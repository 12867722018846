import i18n from '@/translations/i18n';

import { ActionHistoryCategory } from '@/app/_features/action-history/_common/types';
import { Namespaces } from '@/translations/namespaces';

export const getActionHistoryCategoryLabel = (category: ActionHistoryCategory): string => {
	const actionHistoryCategoryTranslationKey = new Map<ActionHistoryCategory, string>([
		[ActionHistoryCategory.ALERT, 'grid.categoryLabels.alert'],
		[ActionHistoryCategory.COLLECTOR, 'grid.categoryLabels.collector'],
		[ActionHistoryCategory.EMAIL, 'grid.categoryLabels.email'],
		[ActionHistoryCategory.INTEGRATION, 'grid.categoryLabels.integration'],
		[ActionHistoryCategory.INVESTIGATION, 'grid.categoryLabels.investigation'],
		[ActionHistoryCategory.NOTE, 'grid.categoryLabels.note'],
		[ActionHistoryCategory.QUERY_RESULT, 'grid.categoryLabels.queryResult'],
		[ActionHistoryCategory.TENANT, 'grid.categoryLabels.tenant'],
		[ActionHistoryCategory.USER, 'grid.categoryLabels.user'],
		[ActionHistoryCategory.ENDPOINT, 'grid.categoryLabels.endpoint'],
		[ActionHistoryCategory.FRANCHISE, 'grid.categoryLabels.franchise'],
	]);

	return i18n.t(actionHistoryCategoryTranslationKey.get(category) ?? '', { ns: Namespaces.ActionHistory });
};
