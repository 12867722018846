import classNames from 'classnames';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';

import { FormFieldError } from '@/app/_common/_components/form-components/form-field-error';

import styles from './form-checkbox.module.scss';

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
	const { validationMessage, touched, id, valid, disabled, hint, optional, message, label, ...rest } = fieldRenderProps;

	const { field } = rest;
	const controlledFieldProps = field
		? {
				value: field.value,
				onChange: field.onChange,
		  }
		: {};

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hindId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	const fullLabel = optional ? `${label} '(Optional)'` : label;

	return (
		<FieldWrapper className={styles.fieldWrapper}>
			<Checkbox
				className={classNames(styles.formCheckbox, { [styles.error]: message })}
				ariaDescribedBy={`${hindId} ${errorId}`}
				valid={valid}
				label={fullLabel}
				id={id}
				disabled={disabled}
				{...rest}
				{...controlledFieldProps}
			/>
			{showHint && <Hint id={hindId}>{hint}</Hint>}
			{showValidationMessage && <FormFieldError>{validationMessage}</FormFieldError>}
			{message && <FormFieldError>{message}</FormFieldError>}
		</FieldWrapper>
	);
};
