import { Controller, FieldValues } from 'react-hook-form';
import { FieldPath, UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Field } from '@progress/kendo-react-form';

import {
	BaseFieldProps,
	ControlledFieldComponentProps,
	FieldComponentProps,
	HostnameField,
} from '@/app/_common/_components/integration-details-form/types';
import { FormInput } from '@/app/_common/_components';
import { TEXT_MAX_LENGTH } from '@/app/_common/_components/integration-details-form/constants';

const BaseField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	disabled,
	register,
	t,
	controllerProps = {},
}: BaseFieldProps<TFieldValues, TName>) => (
	<Field
		{...controllerProps}
		id={HostnameField.Name}
		label={t(HostnameField.Label)}
		type={HostnameField.Type}
		component={FormInput}
		touched={true}
		placeholder={disabled ? undefined : t(HostnameField.Placeholder)}
		optional={true}
		valid={false}
		disabled={disabled}
		{...register(HostnameField.Name as TName, { required: false, maxLength: TEXT_MAX_LENGTH })}
	/>
);

const ControlledHostnameFieldComponent = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
	props: ControlledFieldComponentProps<TFieldValues>,
) => {
	const { control } = props;

	return (
		<Controller<TFieldValues>
			render={(controllerProps: {
				field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
				fieldState: ControllerFieldState;
				formState: UseFormStateReturn<TFieldValues>;
			}) => <BaseField controllerProps={controllerProps} {...props} />}
			name={HostnameField.Name as TName}
			control={control}
		/>
	);
};

ControlledHostnameFieldComponent.displayName = 'ControlledHostnameFieldComponent';

export const HostnameFieldComponent = <TFieldValues extends FieldValues>({ disabled, register, t, control }: FieldComponentProps<TFieldValues>) => {
	if (!control) {
		return <BaseField disabled={disabled} register={register} t={t} />;
	}

	return <ControlledHostnameFieldComponent disabled={disabled} register={register} t={t} control={control} />;
};
