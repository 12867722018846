import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { OpenInNew } from '@mui/icons-material';

import { Button, ButtonSize } from '@/app/_common/_components/button';

import { PlainLink } from './_components';

import styles from './external-link.module.scss';

interface ExternalLinkProps {
	to: string;
	text: string;
	openNewTab?: boolean;
	className?: string;
	icon?: ReactNode;
	isButton?: boolean;
	hasIcon?: boolean;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
	to,
	text,
	openNewTab = true,
	icon = <OpenInNew />,
	className,
	isButton = true,
	hasIcon = true,
}) => {
	const target = openNewTab ? '_blank' : '_self';
	const Icon = hasIcon ? icon : undefined;

	if (!isButton) {
		return <PlainLink className={className} href={to} text={text} target={target} icon={Icon} />;
	}

	return (
		<Button fillMode="outline" size={ButtonSize.Large} className={classNames(styles.button, styles.container, className)}>
			<PlainLink className={styles.link} href={to} text={text} target={target} icon={Icon} />
		</Button>
	);
};
