import { useCallback, useMemo, useState } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { SimpleTable, SimpleTableProps } from '@/app/_common/_components/simple-table';
import { Avatar } from '@/app/_common/_components/avatar';
import { AvatarsList } from '@/app/_common/_components/avatars-list';
import { useOutsideClick } from '@/app/_common/hooks/use-outside-click';
import { getFormattedDate } from '@/app/_common/utils';

import {
	AVATAR_LIST_POPUP_ALIGN,
	AVATAR_LIST_POPUP_ANCHOR_ALIGN,
	AVATAR_LIST_POPUP_COLLISIONS,
	AVATAR_LIST_POPUP_MARGINS,
} from './avatar-list-with-activity.constants';

import styles from './avatar-list-with-activity.module.scss';

interface AvatarActivityItem {
	label: string;
	lastActivity: Date;
}

interface AvatarListWithActivityProps {
	items: AvatarActivityItem[];
	franchiseName: string;
	className?: string;
}

const AVATAR_LIST_TABLE_COLUMN_CONFIG: SimpleTableProps<AvatarActivityItem>['columns'] = [
	{
		prop: 'label',
		header: 'Email',
		render: (label: string) => (
			<>
				<Avatar tooltipEnabled={false} name={label} />
				<span style={{ marginLeft: '8px' }}>{label}</span>
			</>
		),
	},
	{
		prop: 'lastActivity',
		header: 'Last Activity',
		render: getFormattedDate,
	},
];

export const AvatarListWithActivity = ({ items, franchiseName, className }: AvatarListWithActivityProps) => {
	const avatars = useMemo(
		() =>
			items.map(({ label, lastActivity }) => ({
				id: `${label}.${lastActivity.getTime().toString()}`,
				name: label,
				tooltipContent: (
					<>
						<p>{label},</p>
						<p>Last activity: {getFormattedDate(lastActivity)}</p>
					</>
				),
				isFranchiseUser: label === franchiseName,
			})),
		[franchiseName, items],
	);

	const [anchor, setAnchor] = useState<HTMLElement>();
	const { popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		!!anchor,
		useCallback(
			(e) => {
				if (e.target instanceof HTMLElement && anchor instanceof HTMLElement && anchor.contains(e.target)) {
					return;
				}
				setAnchor(undefined);
			},
			[anchor],
		),
	);

	return (
		<>
			<AvatarsList
				items={avatars}
				className={className}
				remainingItemProps={{
					tooltipEnabled: false,
					onClick: (e) => setAnchor(e.currentTarget),
				}}
			/>
			<Popup
				animate={false}
				anchor={anchor}
				popupClass={styles.container}
				show={!!anchor}
				anchorAlign={AVATAR_LIST_POPUP_ANCHOR_ALIGN}
				popupAlign={AVATAR_LIST_POPUP_ALIGN}
				collision={AVATAR_LIST_POPUP_COLLISIONS}
				margin={AVATAR_LIST_POPUP_MARGINS}
			>
				<div ref={popupRef} className={styles.popup}>
					<SimpleTable idProp="lastActivity" columns={AVATAR_LIST_TABLE_COLUMN_CONFIG} items={items} />
				</div>
			</Popup>
		</>
	);
};
