import { ApolloCache, gql, Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { UpdateListAlertsCacheAlertData } from '@/app/advanced-query/_components/advanced-query-subscriptions/types';
import { AlertEdge } from '@/generated/graphql';
import { AlertTypename } from '@/app/_common/constants';

export const updateListAlertsCache = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cache: ApolloCache<any>,
	investigationId?: string,
	alerts?: UpdateListAlertsCacheAlertData[],
	// Response can contain many previously assigned alerts. Processing them can be avoided by passing 'requestAlertsIds'.
	requestAlertsIds?: string[] | null,
): void => {
	if (!investigationId || !alerts?.length) {
		return;
	}

	const alertsIds = requestAlertsIds || alerts.map(({ id }) => id);

	cache.modify({
		fields: {
			listAlerts(existingAlertRefs: Reference | StoreObject, { readField }) {
				const edges = readField<AlertEdge[]>('edges', existingAlertRefs);

				return {
					...(existingAlertRefs || {}),
					edges: edges?.map((edgeRef) => {
						const alertId = readField('id', edgeRef.node);

						if (typeof alertId === 'string' && alertsIds.includes(alertId)) {
							const alert = {
								__typename: AlertTypename.Alert,
								id: alertId,
							};

							const alertData = alerts.find((alert) => alert.id === alertId);

							const updatedAlertRef = cache.writeFragment({
								id: cache.identify(alert),
								data: {
									state: alertData?.state,
									// investigationSummary.id triggers reaction that updates investigation, e.g. investigation.name
									investigationSummary: {
										id: investigationId,
									},
								},
								fragment: gql`
									# eslint-disable-next-line @graphql-eslint/no-unused-fragments
									fragment UpdateListAlertsCache on Alert {
										state {
											alertState
											requesterUser {
												upn
												id
											}
											timestamp
										}
										investigationSummary {
											id
										}
									}
								`,
							});

							return {
								...edgeRef,
								node: updatedAlertRef,
							};
						}

						return edgeRef;
					}),
				};
			},
		},
	});
};
