import { FC, ElementType } from 'react';
import classnames from 'classnames';

import styles from './menu-header.module.scss';

export interface MenuHeaderProps {
	drawerOpened: boolean;
	title: string;
	Icon: ElementType;
	iconStyle?: string;
	style?: string;
	disabled?: boolean;
}

export const MenuHeader: FC<MenuHeaderProps> = ({ drawerOpened, title, Icon, iconStyle, style, disabled }) => {
	return (
		<div className={classnames(styles.headerWrapper, style)} data-testid="side-menu-header">
			<div className={classnames(styles.icon, { [styles.disabled]: disabled }, iconStyle)}>
				<Icon />
			</div>
			<div className={classnames(styles.headerTitle, { [styles.drawerCollapsed]: !drawerOpened, [styles.disabled]: disabled })}>{title}</div>
		</div>
	);
};
