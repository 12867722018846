import { GetAllIntegrations } from '@/app/_common/graphql/queries';
import { IntegrationsBaseDataStore } from '@/app/_common/stores/integrations-store';
import { getTelemetryIntegrationsFiltersInput } from '@/app/_common/utils/get-telemetry-integrations-filters-input/get-telemetry-integrations-filters-input';

export class TelemetryIntegrationsListDataStore extends IntegrationsBaseDataStore {
	constructor() {
		super();
	}

	async readMore(): Promise<void> {
		super.readMore(GetAllIntegrations, getTelemetryIntegrationsFiltersInput());
	}

	read() {
		super.read(GetAllIntegrations, getTelemetryIntegrationsFiltersInput());
	}
}
