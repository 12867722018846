export enum EndpointsPropertiesPaths {
	Id = 'node.id',
	Hostname = 'node.hostname',
	NetworkInformation = 'node.networkInformation',
	OperatingSystem = 'node.operatingSystem',
	OsVendor = 'node.osVendor',
	EdrProduct = 'node.responseIntegration.productName',
	EdrVendor = 'node.responseIntegration.vendor',
	Status = 'node.action.status',
	ActionLastUpdatedTimestamp = 'node.action.last_updated',
	Type = 'node.action.type',
	Timestamp = 'node.timestamp',
	LastUpdated = 'node.last_updated',
	Comment = 'node.action.comment',
	ResponseIntegrationName = 'node.responseIntegration.name',
	IpAddress = 'node.networkInformation.lastIpAddress',
	Expanded = 'expanded',
	CurrentStatus = 'node.currentStatus',
}

export const EndpointsHeadersTranslationKeys: Record<string, string> = {
	[EndpointsPropertiesPaths.Hostname]: 'grid.headers.hostname',
	[EndpointsPropertiesPaths.IpAddress]: 'grid.headers.ipAddress',
	[EndpointsPropertiesPaths.OperatingSystem]: 'grid.headers.operatingSystem',
	[EndpointsPropertiesPaths.EdrVendor]: 'grid.headers.edrVendor',
	[EndpointsPropertiesPaths.EdrProduct]: 'grid.headers.edrProduct',
	[EndpointsPropertiesPaths.ResponseIntegrationName]: 'grid.headers.responseIntegration',
	[EndpointsPropertiesPaths.CurrentStatus]: 'grid.headers.currentStatus',
};

export enum EndpointsGridNoRecordsTranslationProperties {
	NoEndpointsForFilterConfiguration = 'noEndpointsForFilterConfiguration',
}

export const EndpointsGridNoRecordsTranslationKeys: Record<string, string> = {
	[EndpointsGridNoRecordsTranslationProperties.NoEndpointsForFilterConfiguration]: 'grid.noRecords.noEndpointsForFilterConfiguration',
};

export enum EndpointStatusTranslationSuffixes {
	ISOLATED = 'isolated',
	DEISOLATED = 'deisolated',
}

export enum EndpointTooltipTranslationSuffixes {
	ISOLATED = 'isolated',
	DEISOLATED = 'deisolated',
	PENDING_TO_ISOLATE = 'pendingToIsolate',
	PENDING_TO_DEISOLATE = 'pendingToDeisolate',
	TIMEOUT_TO_ISOLATE = 'timeoutToIsolate',
	TIMEOUT_TO_DEISOLATE = 'timeoutToDeisolate',
	FAILED_TO_ISOLATE = 'failedToIsolate',
	FAILED_TO_DEISOLATE = 'failedToDeisolate',
	CANCELLED_TO_ISOLATE = 'cancelledToIsolate',
	CANCELLED_TO_DEISOLATE = 'cancelledToDeisolate',
}

export enum EndpointCurrentStatus {
	ISOLATED = 'isolated',
	DEISOLATED = 'deisolated',
	PENDING = 'pending',
}

export const EndpointsHeaderCountersKeys: Record<string, string> = {
	[EndpointsPropertiesPaths.Hostname]: 'hostname',
	[EndpointsPropertiesPaths.IpAddress]: 'lastIpAddress',
	[EndpointsPropertiesPaths.OperatingSystem]: 'operatingSystem',
	[EndpointsPropertiesPaths.EdrProduct]: 'product',
	[EndpointsPropertiesPaths.ResponseIntegrationName]: 'responseIntegration',
	[EndpointsPropertiesPaths.EdrVendor]: 'vendor',
	[EndpointsPropertiesPaths.CurrentStatus]: 'currentStatus',
};

export enum GetEndpointsTableHeadersCountTypename {
	EndpointsTableHeadersCount = 'EndpointsTableHeadersCount',
	Error = 'Error',
}

export enum EndpointTypename {
	Endpoint = 'Endpoint',
	EndpointEdge = 'EndpointEdge',
	Error = 'Error',
}

export enum EndpointActionElementsTypename {
	EndpointActionElements = 'EndpointActionElements',
	Error = 'Error',
}
