import { IntegrationStatus } from '@/generated/graphql';
import { IntegrationsStatusTranslationSuffixes } from '@/app/_common/constants';

export const getIntegrationStatusLabelSuffix = (status: IntegrationStatus): IntegrationsStatusTranslationSuffixes => {
	if (status === IntegrationStatus.Healthy) {
		return IntegrationsStatusTranslationSuffixes.HEALTHY;
	}

	if (status === IntegrationStatus.NotHealthy) {
		return IntegrationsStatusTranslationSuffixes.NOT_HEALTHY;
	}

	if (status === IntegrationStatus.Provisioning) {
		return IntegrationsStatusTranslationSuffixes.PROVISIONING;
	}

	if (status === IntegrationStatus.NotAvailable) {
		return IntegrationsStatusTranslationSuffixes.NOT_AVAILABLE;
	}

	return IntegrationsStatusTranslationSuffixes.NOT_AVAILABLE;
};
