import { ReactNode } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, FormElement, FormProps } from '@progress/kendo-react-form';
import { Control } from 'react-hook-form/dist/types';

import { EnvironmentVariable, IntegrationSourceType } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';
import { CreateFormFields } from './_utils';
import { IntegrationDetailsFormType } from '@/app/_common/_components/integration-details-form/types';
import { useIntegrationDetailsFormValidator } from '@/app/_common/_components/integration-details-form/hooks';
import { IntegrationDetailsFormDisabledProp } from '@/app/_common/types';
import {
	UpdateFormDynamicColumnHeader,
	UpdateFormStaticColumnHeader,
	MultipleColumnsLayout,
	NameFieldComponent,
	DescriptionFieldComponent,
	HostnameFieldComponent,
} from '@/app/_common/_components/integration-details-form/_components';

import styles from './integration-details-form.module.scss';

const enum ColumnName {
	Static = 'static',
	Dynamic = 'dynamic',
	StaticAutoDetected = 'staticAutoDetected',
}

interface IntegrationDetailsFormProps {
	formDisabledState?: IntegrationDetailsFormDisabledProp;
	initialValues?: Record<string, string>;
	register: UseFormRegister<FieldValues>;
	environments: Record<string, EnvironmentVariable>[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: { [key: string]: any };
	formType?: IntegrationDetailsFormType;
	sourceType?: IntegrationSourceType;
	control?: Control; // This property is required by the react-hook-form Controller component to make input controlled if needed
}

const columnHeaderComponents: Record<IntegrationDetailsFormType, { static: ReactNode; dynamic: ReactNode }> = {
	[IntegrationDetailsFormType.Default]: { static: null, dynamic: null },
	[IntegrationDetailsFormType.EditForm]: {
		static: <UpdateFormStaticColumnHeader />,
		dynamic: <UpdateFormDynamicColumnHeader />,
	},
};

export const IntegrationDetailsForm = ({
	formDisabledState,
	initialValues,
	register,
	environments,
	errors,
	formType = IntegrationDetailsFormType.Default,
	sourceType,
	control,
}: IntegrationDetailsFormProps) => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);
	const formValidator = useIntegrationDetailsFormValidator();
	const areStaticFieldsDisabled = formDisabledState === IntegrationDetailsFormDisabledProp.AllFieldsDisabled;
	const areDynamicFieldsDisabled = formDisabledState !== IntegrationDetailsFormDisabledProp.Enabled;
	const areStaticAutoDetectedDisabled = formDisabledState !== IntegrationDetailsFormDisabledProp.StaticAutoDetectedEnabled;

	const integrationNameField = (
		<div className={styles.field} key={'integrationNameField'}>
			<NameFieldComponent disabled={areStaticFieldsDisabled} register={register} control={control} t={t} />
		</div>
	);

	const descriptionField = (
		<div className={styles.field} key={'descriptionField'}>
			<DescriptionFieldComponent disabled={areStaticFieldsDisabled} register={register} control={control} t={t} />
		</div>
	);

	const hostnameField = (
		<div className={styles.field} key={'hostnameField'}>
			<HostnameFieldComponent disabled={areStaticAutoDetectedDisabled} register={register} control={control} t={t} />
		</div>
	);

	const headers = columnHeaderComponents[formType];
	const dynamicForm = CreateFormFields(environments, register, errors, areDynamicFieldsDisabled, control);
	const staticFields = [integrationNameField, descriptionField];
	const autoDetectedFields = [hostnameField];

	const columns = [
		{ id: ColumnName.Static, fields: staticFields, header: headers.static },
		sourceType === IntegrationSourceType.AutoDetected
			? { id: ColumnName.StaticAutoDetected, fields: autoDetectedFields, header: null }
			: { id: ColumnName.Dynamic, fields: dynamicForm, header: headers.dynamic },
	];

	return (
		<div className={styles.integrationDetailsForm}>
			<Form
				initialValues={initialValues}
				key={JSON.stringify(initialValues)}
				validator={formValidator as FormProps['validator']}
				render={() => (
					<FormElement>
						<fieldset className={styles.fieldset}>
							<MultipleColumnsLayout columns={columns} />
						</fieldset>
					</FormElement>
				)}
			/>
		</div>
	);
};
