import { ApolloCache, Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import _isNumber from 'lodash/isNumber';

import { AlertTypename, InvestigationTypename } from '@/app/_common/constants';
import { AlertAssignmentCount, Investigation } from '@/generated/graphql';

export const cacheUpdateHelperUnassignDashboard = (cache: ApolloCache<unknown>, unassignedAlertIds: string[], investigations: Investigation[]) => {
	for (const alertId of unassignedAlertIds) {
		const identifier = cache.identify({
			__typename: AlertTypename.Alert,
			id: alertId,
		});

		cache.modify({
			id: identifier,
			fields: {
				state() {
					return null;
				},
				investigationSummary() {
					return null;
				},
			},
		});
	}

	for (const investigation of investigations) {
		const { id, alerts, last_updated } = investigation;

		const identifier = cache.identify({
			__typename: InvestigationTypename.Investigation,
			id,
		});

		cache.modify({
			id: identifier,
			fields: {
				last_updated() {
					return last_updated;
				},
				alerts() {
					return alerts;
				},
			},
		});
	}

	cache.modify({
		fields: {
			getAlertCount(existingAlertCount: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) {
				const assignment = readField<AlertAssignmentCount>('assignment', existingAlertCount);
				const assigned = assignment?.assigned;
				const hasAssigned = _isNumber(assigned);
				const unassigned = assignment?.unassigned;
				const hasUnassigned = _isNumber(unassigned);

				return {
					...(existingAlertCount ?? {}),
					assignment: {
						...(assignment ?? {}),
						...(hasAssigned ? { assigned: assigned - unassignedAlertIds.length } : {}),
						...(hasUnassigned ? { unassigned: unassigned + unassignedAlertIds.length } : {}),
					},
				};
			},
		},
	});
};
