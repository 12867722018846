import classNames from 'classnames';

import styles from './badge.module.scss';

interface BadgeProps {
	text: string;
	color?: BadgeColor;
}

export enum BadgeColor {
	Purple = 'purple',
}

export const Badge = ({ text, color = BadgeColor.Purple }: BadgeProps) => {
	return <div className={classNames(styles.badge, styles[color])}>{text}</div>;
};
