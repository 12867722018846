import { BoolStrings } from '@/app/collectors/_common/types';

export const handleBoolErrorMessage = 'Received string do not match field type bool';

export const handleFieldTypeBool = (str: string): boolean => {
	if (str !== BoolStrings.True && str !== BoolStrings.False) {
		throw handleBoolErrorMessage;
	}

	return str === BoolStrings.True;
};
