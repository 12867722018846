import { Controller, FieldValues } from 'react-hook-form';
import { FieldPath, UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Field } from '@progress/kendo-react-form';

import {
	BaseFieldProps,
	ControlledFieldComponentProps,
	DescriptionField,
	FieldComponentProps,
} from '@/app/_common/_components/integration-details-form/types';
import { FormInput } from '@/app/_common/_components';
import { TEXT_MAX_LENGTH } from '@/app/_common/_components/integration-details-form/constants';

const BaseField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	disabled,
	register,
	t,
	controllerProps = {},
}: BaseFieldProps<TFieldValues, TName>) => (
	<Field
		{...controllerProps}
		id={DescriptionField.Name}
		label={t(DescriptionField.Label)}
		type={DescriptionField.Type}
		component={FormInput}
		touched={true}
		placeholder={disabled ? undefined : t(DescriptionField.Placeholder)}
		optional={true}
		valid={false}
		disabled={disabled}
		{...register(DescriptionField.Name as TName, { required: false, maxLength: TEXT_MAX_LENGTH })}
	/>
);

const ControlledDescriptionFieldComponent = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
	props: ControlledFieldComponentProps<TFieldValues>,
) => {
	const { control } = props;

	return (
		<Controller<TFieldValues>
			render={(controllerProps: {
				field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
				fieldState: ControllerFieldState;
				formState: UseFormStateReturn<TFieldValues>;
			}) => <BaseField controllerProps={controllerProps} {...props} />}
			name={DescriptionField.Name as TName}
			control={control}
		/>
	);
};

ControlledDescriptionFieldComponent.displayName = 'ControlledDescriptionFieldComponent';

export const DescriptionFieldComponent = <TFieldValues extends FieldValues>({
	disabled,
	register,
	t,
	control,
}: FieldComponentProps<TFieldValues>) => {
	if (!control) {
		return <BaseField disabled={disabled} register={register} t={t} />;
	}

	return <ControlledDescriptionFieldComponent disabled={disabled} register={register} t={t} control={control} />;
};
