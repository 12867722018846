import { action, makeObservable } from 'mobx';
import { StoreObject } from '@apollo/client/utilities';
import { Reference } from '@apollo/client';

import { Mutation, MutationUnassignQueryResultFromInvestigationArgs, QueryResultConnection, QueryResultEdge } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { UnassignQueryResultFromInvestigation } from '@/app/_common/graphql/queries';
import { InvestigationTypename } from '@/app/_common/constants';

export class QueryResultsUnassignDataStore extends GraphqlBaseDataStore<Mutation, MutationUnassignQueryResultFromInvestigationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			unassign: action,
		});
	}

	async unassign(args: Omit<MutationUnassignQueryResultFromInvestigationArgs, 'tenantId'>) {
		const variables: MutationUnassignQueryResultFromInvestigationArgs = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUnassignQueryResultFromInvestigationArgs>({
			mutation: UnassignQueryResultFromInvestigation,
			variables,
			update: (cache) => {
				cache.modify({
					fields: {
						listQueryResults(existingQueryResultsRefs: Reference | StoreObject, { readField }): QueryResultConnection {
							const edges = readField<QueryResultEdge[]>('edges', existingQueryResultsRefs);

							return {
								...existingQueryResultsRefs,
								edges: edges?.filter((edge) => readField('id', edge.node) !== variables.queryResultId),
							} as QueryResultConnection;
						},
					},
				});

				const identifier = cache.identify({
					__typename: InvestigationTypename.Investigation,
					id: variables.investigationId,
				});

				cache.modify({
					id: identifier,
					fields: {
						queryResults(current: ReadonlyArray<Reference | StoreObject>) {
							return current.filter((summary) => (summary as StoreObject).id !== variables.queryResultId);
						},
					},
				});
			},
		});

		return response;
	}
}
