import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';

import styles from './static-column-header.module.scss';

export const UpdateFormStaticColumnHeader = () => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);

	return <div className={styles.container}>{t('columnHeader.details')}</div>;
};
