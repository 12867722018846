import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface TrendMicroAlertEvidenceProps {
	evidence: Evidence;
}

export const TrendMicroAlertEvidence = ({ evidence }: TrendMicroAlertEvidenceProps) => {
	return <AlertEvidence evidence={evidence} />;
};
