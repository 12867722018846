import { Severity } from '@/generated/graphql';
import { AlertConfidence } from './alerts.constants';

export interface FileApiDTO {
	recommendation?: string | null;
	description?: string | null;
	report: Report;
	signatures: Signature[];
	event_id?: string;
	severity: string;
	type: AlertTypes;
	faereconfidence: string;
	timestamp: number;
	summary: string;
	shortdesc: string;
	name: string;
	PK: string;
	trigger_date: string;
	origins: string;
	stopdate: string;
}

export interface EvidenceApiDTO {
	alert_id: string;
	evidence_category: string;
	evidence_id: string;
	external_device_id: string;
	external_ip: string;
	hostname: string;
	hosttype: string;
	local_ip: string;
	os: string;
	os_version: string;
	timestamp: string;
	confidence?: string;
	severity: string;
	description?: string;
	mitre_tactics?: string[];
	mitre_tactics_id?: string[];
	mitre_techniques?: string[];
	mitre_techniques_id?: string[];
	name?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	raw?: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	alert_raw?: Record<string, any> | string;
	users?: string[];
	user?: string;
}

interface Report {
	customer: string;
	details?: Details;
}

export enum AlertTypes {
	BOOST = 'boost',
	DNS = 'dnsserver',
	HOST = 'hostname',
	FW = 'fw',
	PROXY = 'webproxy',
}

export interface Details {
	signatures: Signature[];
}

export interface Signature {
	name: string;
	important_fields: string[];
	boost_score: string;
	silenced?: boolean;
	sessions: SessionItem[];
	key?: string;
	severity: Severity;
	confidence: AlertConfidence;
	shortdesc: string;
	alert_ref: string;
}

export interface SessionItem {
	[key: string]: string;
}

export interface DescriptionItem {
	[key: string]: string;
}

export enum AlertDetailsKeyPrefix {
	DetailsButton = 'details-button',
	ThreatIntelligencePanel = 'threatIntelligencePanel',
	Error = 'error',
}

export const BOOST_SCORE_ERROR_THRESHOLD = 100;

export const HOST_USER_EVIDENCE_CATEGORY_VALUE = 'Host';

export const CROWDSTRIKE_EVIDENCE_CATEGORY_CORRELATED_ALERT_VALUE = 'CorrelatedAlert';
export const GOOGLE_WORKSPACE_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const MICROSOFT_DEFENDER_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const CISCO_MERAKI_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const PALO_ALTO_NGFW_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const FORTINET_FORTIGATE_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const OKTA_WORKFORCE_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const SECIOSS_SECIOSSLINK_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
export const TREND_MICRO_EVIDENCE_CATEGORY_ALERT_VALUE = 'Other';
