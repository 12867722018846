import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

export const getActionLabel = (telemetryIntegrationType: TelemetryIntegrationType): string => {
	switch (telemetryIntegrationType) {
		case TelemetryIntegrationType.Cloud:
			return i18n.t('grid.actions.sectionTitles.integrationActions', { ns: [Namespaces.Integrations] });
		case TelemetryIntegrationType.SecureSyslog:
			return i18n.t('grid.actions.sectionTitles.syslogIntegration', { ns: [Namespaces.Integrations] });
		default:
			return i18n.t('grid.actions.sectionTitles.integrationActions', { ns: [Namespaces.Integrations] });
	}
};
