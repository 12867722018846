import { ApolloError } from '@apollo/client';
import { makeAutoObservable, reaction } from 'mobx';

import { Collector, CollectorEdge, CollectorType } from '@/generated/graphql';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { UrlStore } from '@/app/_common/stores';
import { CollectorsDataStore } from '@/app/collectors/_common/stores';
import { collectorTabs } from '@/app/collectors/_common/constants';

interface State {
	activeTab: CollectorType.SecureSyslog | CollectorType.Cloud;
}

export class CollectorsViewStore {
	private urlStore = injectInterface(this, UrlStore);
	private dataStore = injectInterface(this, CollectorsDataStore);

	public state: State = {
		activeTab: CollectorType.SecureSyslog,
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		this.initState();
	}

	get data(): CollectorEdge[] {
		return this.dataStore.collectors;
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get error(): ApolloError | undefined {
		return this.dataStore.error;
	}

	get activeTab(): CollectorType.SecureSyslog | CollectorType.Cloud {
		return this.state.activeTab;
	}

	get activeTabNumber(): number {
		return collectorTabs.findIndex((tab) => tab === this.activeTab);
	}

	getCollectorByType = (type: CollectorType) => {
		return this.data.find((edge) => edge.node.type === type)?.node;
	};

	get secureSyslogCollector(): Collector | null {
		return this.getCollectorByType(CollectorType.SecureSyslog) ?? null;
	}

	get cloudCollector(): Collector | null {
		return this.getCollectorByType(CollectorType.Cloud) ?? null;
	}

	getCloudCollectorId(): string | undefined {
		return this.cloudCollector?.id;
	}

	setActiveTab = (tab: CollectorType.SecureSyslog | CollectorType.Cloud) => {
		this.state.activeTab = tab;
	};

	setActiveTabNumber = (tabNumber: number) => {
		this.setActiveTab(this.getTabByTabNumber(tabNumber));
	};

	private initState = () => {
		const queryParamsActiveTab = this.urlStore.getStateVariables('collectorsActiveTab');

		if (queryParamsActiveTab) {
			if (queryParamsActiveTab === CollectorType.SecureSyslog || queryParamsActiveTab === CollectorType.Cloud) {
				this.setActiveTab(queryParamsActiveTab);
			}
		}
	};

	private getTabByTabNumber = (tabNumber: number) => {
		return collectorTabs[tabNumber];
	};

	activeTabDisposer = reaction(
		() => this.state.activeTab,
		() => {
			this.urlStore.updateStore({ collectorsActiveTab: this.activeTab });
		},
	);

	disposer() {
		this.activeTabDisposer();
	}
}

export default CollectorsViewStore;

export type CollectorsRow = CollectorsViewStore['data'][0];
