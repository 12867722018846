import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';

import { DASHBOARD_DATE_TIME_PERIOD_OPTION_KEYS, DateTimePickerOptionKeys, EarliestCustomDateStart } from '@/app/_common/constants';
import { useInDocument, useTimeRangeOptions } from '@/app/_common/hooks';
import { momentToUtcString } from '@/app/_common/utils';
import { FormDateRangePicker, InfoTooltip, RangePeriodValue, Switch } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import {
	ActionHistoryDataGridViewStore,
	ActionHistorySystemActionsSwitchStore,
	ActionHistoryTimeRangeFilterStore,
} from '@/app/_features/action-history/_common/stores';

import styles from './action-history-data-grid-header.module.scss';

type DateRangePickerEvent = { target?: DropDownList | DateTimePicker; value?: RangePeriodValue };

export interface ActionHistoryDataGridHeaderProps {
	showActionSourceSelect?: boolean;
}

// Will be enabled in a future story. Read https://nttsecurityholdings.atlassian.net/browse/XDR-11506
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ActionHistoryDataGridHeader = observer(({ showActionSourceSelect = true }: ActionHistoryDataGridHeaderProps) => {
	const { t } = useTranslation(Namespaces.ActionHistory);
	const options = useTimeRangeOptions(DASHBOARD_DATE_TIME_PERIOD_OPTION_KEYS);
	const timeRangeFiltersStore = useInstance(ActionHistoryTimeRangeFilterStore);
	const gridStore = useInstance(ActionHistoryDataGridViewStore);
	const systemActionsSwitchStore = useInstance(ActionHistorySystemActionsSwitchStore);

	useInDocument(timeRangeFiltersStore.setInDocument);

	const handleDateRangePickerChange = (event: DateRangePickerEvent) => {
		const { value, key } = event?.value || {};
		const { start: from, end: to } = value || {};

		if (key === DateTimePickerOptionKeys.CUSTOM) {
			timeRangeFiltersStore.setTimeRange(key, from, to);
		} else {
			timeRangeFiltersStore.setTimeRange(key as DateTimePickerOptionKeys);
		}
	};

	// Will be enabled in a future story. Read https://nttsecurityholdings.atlassian.net/browse/XDR-11506
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleMultiselectChange = (event: MultiSelectChangeEvent) => {
		if (!event.value.length) {
			return;
		}

		gridStore.setActiveMultiselectFilters(event.value);
	};

	return (
		<header className={styles.container}>
			<h5 className={styles.title}>
				{t('title')}
				<InfoTooltip
					id={t('title')}
					iconClassName={styles.infoTooltip}
					description={<p className={styles.tooltipDescription}>{t('titleTooltip')}</p>}
					place="right"
				/>
			</h5>
			{/*
			Will be enabled in a future story. Read https://nttsecurityholdings.atlassian.net/browse/XDR-11506
			{showActionSourceSelect && (
				<MultiSelect
					className={styles.multiselect}
					data={ActionHeadersMultiselectFilters}
					value={gridStore.activeMultiselectFilters}
					onChange={handleMultiselectChange}
					filterable={false}
					textField="label"
					dataItemKey="value"
				/>
			)} */}
			{/* @ts-ignore*/}
			<FormDateRangePicker
				key={timeRangeFiltersStore.timeRangeValue.key}
				dataItemKey="key"
				textField="label"
				options={options}
				onChange={handleDateRangePickerChange}
				value={timeRangeFiltersStore.timeRangeValue}
				formatter={momentToUtcString}
				isMaximumDateNow={true}
				utc={true}
				hideCustomDateLabels={true}
				earliestCustomDateStart={EarliestCustomDateStart.ONE_YEAR_AGO}
			/>
			<Switch
				checked={Boolean(systemActionsSwitchStore.showSystemActions)}
				onChange={systemActionsSwitchStore.toggleShowSystemActions}
				text={t('showSystemActions')}
			/>
		</header>
	);
});
