import { TFunction } from 'i18next';

interface GetTranslationKeyForUserTypeParams {
	t: TFunction;
	isFranchiseUser: boolean;
	franchiseUserTKey: string;
	tenantUserTKey: string;
}

export const getTranslationKeyForUserType = ({ t, isFranchiseUser, franchiseUserTKey, tenantUserTKey }: GetTranslationKeyForUserTypeParams) => {
	return isFranchiseUser ? t(franchiseUserTKey) : t(tenantUserTKey);
};
