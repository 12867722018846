import { gql } from '@apollo/client';

import { CoreIntegrationDefinitionComponentsFragment } from '@/app/_common/graphql';

export const ListIntegrationDefinitions = gql`
	query ListIntegrationDefinitions($tenantId: ID!, $filtersInput: FiltersInput) {
		listIntegrationDefinitions(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					collectorType
					categories
					product
					vendor {
						name
						url
						logoFileName
					}
					state
					stateReason
					documentationUrl
					abstract
					last_updated
					timestamp
					isResponse
					isTelemetry
					responseActions
					...CoreIntegrationDefinitionComponentsFragment
				}
			}
		}
	}
	${CoreIntegrationDefinitionComponentsFragment}
`;

export const GetIntegrationDefinition = gql`
	query GetIntegrationDefinition($tenantId: ID!, $id: ID!) {
		getIntegrationDefinition(tenantId: $tenantId, id: $id) {
			... on IntegrationDefinition {
				id
				categories
				product
				vendor {
					name
					url
					logoFileName
				}
				documentationUrl
				...CoreIntegrationDefinitionComponentsFragment
			}
			... on Error {
				code
				message
				correlationId
			}
		}
	}
	${CoreIntegrationDefinitionComponentsFragment}
`;

export const GetIntegrationDefinitionForIntegrationDelete = gql`
	query GetIntegrationDefinitionForIntegrationDelete($tenantId: ID!, $id: ID!) {
		getIntegrationDefinition(tenantId: $tenantId, id: $id) {
			... on IntegrationDefinition {
				vendor {
					name
					logoFileName
				}
			}
			... on Error {
				code
				message
				correlationId
			}
		}
	}
`;

export const ValidateIntegrationParametersSubscription = gql`
	subscription ValidateIntegrationParametersSubscription($tenantId: ID!, $trackingId: ID!) {
		validateIntegrationParametersResult(tenantId: $tenantId, trackingId: $trackingId) {
			trackingId
			success
			tenantId
			message
			code
		}
	}
`;

export const ValidateIntegrationParametersMutation = gql`
	mutation ValidateIntegrationParameters($tenantId: ID!, $integrationParameters: ValidateIntegrationParametersInput!) {
		validateIntegrationParameters(tenantId: $tenantId, integrationParameters: $integrationParameters) {
			message
			code
			additionalInformation {
				name
				value
			}
		}
	}
`;
