import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { ArtifactType, UpdatedBy } from '@/generated/graphql';
import { LoadingSpinner } from '@/app/_common/_components/loading-spinner/loading-spinner';
import { Note } from '@/app/_common/_components/note';
import { getUpdatedByLabels } from '@/app/_common/utils/';
import { InvestigationNotesViewStore } from '@/app/investigation-details/_common/stores/notes';
import { InvestigationDetailsViewStore } from '@/app/investigation-details/_common/stores';
import { AuthStore } from '@/app/_common/stores';
import { CreateNote } from '@/app/_common/_components';

import styles from './notes-panel.module.scss';

export const NotesPanel: FC = observer(() => {
	const {
		notes,
		loading,
		createLoading,
		updateLoading,
		focusedNoteId,
		editedNoteId,
		isNoteWindowOpen,
		deleteNote,
		submitCreateNote,
		updateNote,
		openNoteWindow,
		closeNoteWindow,
		sharePanelNote,
		shareNoteWindow,
		setEditedNoteId,
		clearEditedNoteId,
	} = useInstance(InvestigationNotesViewStore);

	const { investigationId } = useInstance(InvestigationDetailsViewStore);

	const authStore = useInstance(AuthStore);

	const { t } = useTranslation(Namespaces.Notes);

	const notesPanelRef = useRef<HTMLDivElement | null>(null);

	const handleSubmitNewNote = async (noteContent: string) => {
		if (!investigationId) {
			return;
		}
		const newNote = {
			noteContent,
			artifactId: investigationId,
			artifactType: ArtifactType.Investigation,
		};
		await submitCreateNote(newNote);
	};

	const createNoteMaxHeight = notesPanelRef.current ? notesPanelRef.current.offsetHeight / 2 : undefined;

	return (
		<div className={classNames(styles.container)} ref={notesPanelRef} data-testid="notes-panel">
			<header className={styles.header}>
				<div className={styles.title}>
					<h4>{t('title')}</h4>
					<span>{notes?.length}</span>
				</div>

				<CreateNote maxHeight={createNoteMaxHeight} onSubmit={handleSubmitNewNote} loading={createLoading} />
			</header>
			<div className={styles.notesList}>
				{loading && <LoadingSpinner className={styles.loadingSpinner} />}
				{!loading &&
					notes
						?.map(({ node }) => {
							return (
								<Note
									data-testid="note"
									key={node.id}
									updatedDate={node.last_updated}
									createdDate={node.timestamp}
									type={node.artifactType}
									noteId={node.id}
									editedNoteId={editedNoteId}
									focusedNoteId={focusedNoteId}
									noteContent={node.noteContent}
									names={getUpdatedByLabels(node.requesterUser?.upn, (node.updatedBy as UpdatedBy[]) || [], authStore.franchiseName)}
									loggedUserEmail={authStore.userEmail}
									deleteNote={deleteNote}
									updateNote={updateNote}
									isNoteWindowOpen={isNoteWindowOpen}
									openNoteWindow={openNoteWindow}
									closeNoteWindow={closeNoteWindow}
									sharePanelNote={sharePanelNote}
									shareNoteWindow={shareNoteWindow}
									onEdit={setEditedNoteId}
									onCancelEdit={clearEditedNoteId}
									loading={updateLoading}
									franchiseName={authStore.franchiseName}
								/>
							);
						})
						.reverse()}
				{notes?.length === 0 && <p className={styles.emptyNotesPanel}>{t('emptyNotesPanel')}</p>}
			</div>
		</div>
	);
});
