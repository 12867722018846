import { IntegrationStatus } from '@/generated/graphql';

export const getIntegrationStatusColor = (value?: IntegrationStatus): string => {
	switch (value) {
		case IntegrationStatus.Healthy:
			return 'var(--integrationStatusHealthyColor)';
		case IntegrationStatus.NotAvailable:
			return 'var(--integrationStatusNotAvailableColor)';
		case IntegrationStatus.NotHealthy:
			return 'var(--integrationStatusNotHealthyColor)';
		case IntegrationStatus.Provisioning:
			return 'var(--integrationStatusProvisioningColor)';
		default:
			return 'var(--integrationStatusEmptyColor)';
	}
};
