import { action, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import {
	Mutation,
	MutationValidateIntegrationParametersArgs,
	QueryListIntegrationDefinitionsArgs,
	ValidateIntegrationParametersInput,
} from '@/generated/graphql';
import { ValidateIntegrationParametersMutation } from '@/app/_common/graphql/queries';

export type ValidateIntegrationMutationResult = Pick<Mutation, 'validateIntegrationParameters'>;

export class ValidateIntegrationDataStore extends GraphqlBaseDataStore<ValidateIntegrationMutationResult, QueryListIntegrationDefinitionsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			validate: action,
		});
	}

	async validate(args: ValidateIntegrationParametersInput) {
		const variables: MutationValidateIntegrationParametersArgs = {
			tenantId: this.authStore.currentTenantId,
			integrationParameters: args,
		};

		return await this.mutate<Mutation, MutationValidateIntegrationParametersArgs>({
			mutation: ValidateIntegrationParametersMutation,
			variables,
		});
	}
}
