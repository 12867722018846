import { gql, Reference } from '@apollo/client';
import { action, makeObservable } from 'mobx';
import _pick from 'lodash/pick';
import _isNumber from 'lodash/isNumber';
import { StoreObject } from '@apollo/client/utilities';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { assignAlertListInvestigationUpdate } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';
import { CreateInvestigationWithAlerts } from '@/app/_common/graphql/queries';
import { InvestigationTypename, AlertStateOptions, AlertTypename } from '@/app/_common/constants';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { AlertAssignmentCount, AlertEdge, Mutation, MutationCreateInvestigationArgs } from '@/generated/graphql';

export class AlertsCreateInvestigationDataStore extends GraphqlBaseDataStore<Mutation, MutationCreateInvestigationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			create: action,
		});
	}

	async create(data: Omit<MutationCreateInvestigationArgs, 'tenantId' | 'alertIds'>, alertIds: string[] = []) {
		const variables = {
			...data,
			alertIds,
			tenantId: this.authStore.currentTenantId,
		};

		const requesterUser = _pick(this.authStore.user, ['upn', 'id']);

		const response = await this.mutate<Mutation, MutationCreateInvestigationArgs>({
			mutation: CreateInvestigationWithAlerts,
			variables,
			update(cache, { data }) {
				if (!data?.createInvestigation || data?.createInvestigation.__typename !== InvestigationTypename.Investigation) {
					return;
				}

				const investigationId = data?.createInvestigation?.id;
				const investigationCreatedTimestamp = data?.createInvestigation?.timestamp;

				if (!investigationId || alertIds.length === 0) {
					return;
				}

				cache.modify({
					fields: {
						getAlertCount(existingAlertCount: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) {
							const assignment = readField<AlertAssignmentCount>('assignment', existingAlertCount);
							const assigned = assignment?.assigned;
							const hasAssigned = _isNumber(assigned);
							const unassigned = assignment?.unassigned;
							const hasUnassigned = _isNumber(unassigned);

							return {
								...(existingAlertCount || {}),
								assignment: {
									...(assignment || {}),
									...(hasAssigned ? { assigned: assigned + alertIds.length } : {}),
									...(hasUnassigned ? { unassigned: unassigned - alertIds.length } : {}),
								},
							};
						},
						listAlerts(existingAlertRefs: Reference | StoreObject, { readField }) {
							const edges = readField<AlertEdge[]>('edges', existingAlertRefs);

							return {
								...(existingAlertRefs || {}),
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								edges: edges?.map((edgeRef: AlertEdge) => {
									const alertId = readField('id', edgeRef.node);

									if (typeof alertId === 'string' && alertIds.includes(alertId)) {
										const alert = {
											__typename: AlertTypename.Alert,
											id: alertId,
										};

										const updatedAlertRef = cache.writeFragment({
											id: cache.identify(alert),
											data: {
												state: {
													alertState: AlertStateOptions.Assigned,
													timestamp: investigationCreatedTimestamp,
													requesterUser,
												},
												investigationSummary: {
													id: investigationId,
												},
											},
											fragment: gql`
												# eslint-disable-next-line @graphql-eslint/no-unused-fragments
												fragment UpdatedAlert on Alert {
													state {
														alertState
														timestamp
														requesterUser {
															id
															upn
														}
													}
													investigationSummary {
														id
													}
												}
											`,
										});

										return {
											...edgeRef,
											node: updatedAlertRef,
										};
									}

									return edgeRef;
								}),
							};
						},
						listInvestigations: assignAlertListInvestigationUpdate(data?.createInvestigation),
					},
				});
			},
		});

		return response;
	}
}
