import { action, makeObservable } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Mutation, MutationUpdateInvestigationArgs } from '@/generated/graphql';
import { NotificationsStore } from '@/app/_common/stores';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { InvestigationTypename } from '@/app/_common/constants/graphql.constants';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import i18n from '@/translations/i18n';
import { UpdateInvestigation } from '@/app/_common/graphql/queries';
import { Namespaces } from '@/translations/namespaces';

export class AssignInvestigationDataStore extends GraphqlBaseDataStore<Mutation, MutationUpdateInvestigationArgs> {
	private notificationsStore = injectInterface(this, NotificationsStore);

	constructor() {
		super();
		makeObservable(this, {
			updateAssignee: action,
			updatePriority: action,
		});
	}

	async updateAssignee(data: Omit<MutationUpdateInvestigationArgs, 'tenantId'>, isFranchiseAssignee = false): Promise<void> {
		const response = await this.updateInvestigation({
			...data,
		});

		const responseData = response?.data?.updateInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			let title: string;

			if (isFranchiseAssignee) {
				title = responseData?.franchiseAssignee
					? i18n.t('updateInvestigation.updateAssignee.success.assigned', {
							ns: Namespaces.Notifications,
							displayName: responseData?.franchiseAssignee?.displayName,
					  })
					: i18n.t('updateInvestigation.updateAssignee.success.unassigned', { ns: Namespaces.Notifications });
			} else {
				title = responseData?.assignee
					? i18n.t('updateInvestigation.updateAssignee.success.assigned', {
							ns: Namespaces.Notifications,
							displayName: responseData?.assignee?.displayName,
					  })
					: i18n.t('updateInvestigation.updateAssignee.success.unassigned', { ns: Namespaces.Notifications });
			}

			this.notificationsStore.openSuccess({
				title: title,
			});
		} else if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('updateInvestigation.updateAssignee.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError(error);
		}
	}

	async updateStatus(data: Omit<MutationUpdateInvestigationArgs, 'tenantId'>) {
		const response = await this.updateInvestigation({
			...data,
		});

		return response;
	}

	async updatePriority(data: Omit<MutationUpdateInvestigationArgs, 'tenantId'>): Promise<void> {
		const response = await this.updateInvestigation({
			...data,
		});
		const responseData = response?.data?.updateInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			const title = i18n.t('updateInvestigation.updatePriority.success.title', { ns: Namespaces.Notifications, priority: responseData?.priority });
			this.notificationsStore.openSuccess({
				title: title,
			});
		} else if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('updateInvestigation.updatePriority.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError(error);
		}
	}

	async updateInvestigation(data: Omit<MutationUpdateInvestigationArgs, 'tenantId'>) {
		const variables = {
			...data,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUpdateInvestigationArgs>({
			mutation: UpdateInvestigation,
			variables,
		});
		return response;
	}
}
