import classNames from 'classnames';

import styles from './block-item.module.scss';

interface BlockItemProps {
	title: string;
	text: string | JSX.Element;
	className?: string;
	textClassName?: string;
	titleClassName?: string;
}

export const BlockItem = ({ title, text, className, titleClassName, textClassName }: BlockItemProps) => {
	return (
		<div className={classNames(styles.container, className)}>
			<div className={classNames(styles.title, titleClassName)} title={title}>
				{title}
			</div>
			<div className={classNames(styles.text, textClassName)}>{text}</div>
		</div>
	);
};
