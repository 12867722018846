import { action, computed, makeObservable, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { IntegrationEdge, IntegrationStatus } from '@/generated/graphql';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { IntegrationsPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { SwitchTenantStore } from '@/app/_common/stores';
import { ExtendedIntegrationEdge, FilterOption } from '@/app/_common/types';
import { getNullishFilterOption, isLocalhost, migrateToNewestVersion, sortIntegration } from '@/app/_common/utils';
import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';

import { getIntegrationStatusLabelSuffix, TelemetryIntegrationsListDataStore } from '@/app/telemetry/integrations/_common';
import { getTelemetryIntegrationType, getTelemetryIntegrationTypeLabel } from '@/app/_features/integration/_common/utils';
import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

const EMPTY_FIELD_VALUE = 'empty';
const INTEGRATIONS_PERSISTABLE_KEY = 'ls/integrations';
const INTEGRATIONS_VERSION = 'v5'; // change it when you're changing columns

const INITIAL_COLUMNS = {
	[IntegrationsPropertiesPaths.Status]: true,
	[IntegrationsPropertiesPaths.Vendor]: true,
	[IntegrationsPropertiesPaths.Product]: true,
	[IntegrationsPropertiesPaths.TelemetryIntegrationType]: true,
	[IntegrationsPropertiesPaths.Name]: true,
	[IntegrationsPropertiesPaths.CollectorName]: true,
	[IntegrationsPropertiesPaths.Hostname]: true,
	[IntegrationsPropertiesPaths.Description]: true,
	[IntegrationsPropertiesPaths.LastEventTimestamp]: true,
	[IntegrationsPropertiesPaths.CreatedTimestamp]: true,
};

const INITIAL_SORT = [{ field: IntegrationsPropertiesPaths.Vendor, dir: SortDirection.Desc }];

export class TelemetryIntegrationsListViewStore extends DataGridViewStore<ExtendedIntegrationEdge> {
	private dataStore = injectInterface(this, TelemetryIntegrationsListDataStore);
	private switchTenantStore = new SwitchTenantStore('OpenInvestigationsViewStore');

	constructor() {
		super(IntegrationsPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortIntegration, false, INTEGRATIONS_VERSION);

		this.switchTenantStore.init(this.gridState, {
			name: 'ss/integrations/integrations-list',
			properties: ['sort', 'filter', 'selected'],
			storage: window.sessionStorage,
		});

		migrateToNewestVersion(INTEGRATIONS_PERSISTABLE_KEY, INTEGRATIONS_VERSION);

		makeObservable(this, {
			loading: computed,
			currentData: computed,
			hasNextPage: computed,
			readMore: action,
		});

		makePersistable(this.gridState, {
			name: INTEGRATIONS_PERSISTABLE_KEY,
			properties: ['columns', 'version'],
			storage: window.localStorage,
		});
	}

	get loading() {
		return this.dataStore.loading;
	}

	get currentData() {
		return this.dataStore.data;
	}

	get hasNextPage() {
		return this.dataStore.pageInfo?.hasNextPage;
	}

	getFilterOptions = (field: string, isNull?: boolean, emptyFieldLabel?: string) => {
		const counters = this.getCountedValues(field);

		const options: FilterOption[] = [
			...this.getUniqValues(field).map((value) => {
				const partialOption = {
					value: value as string,
					counter: counters[value as string],
				};

				if (field === IntegrationsPropertiesPaths.TelemetryIntegrationType) {
					return {
						...partialOption,
						label: getTelemetryIntegrationTypeLabel(value as TelemetryIntegrationType),
					};
				}

				if (field === IntegrationsPropertiesPaths.Status) {
					return {
						...partialOption,
						label: i18n.t(`grid.status.${getIntegrationStatusLabelSuffix(value as IntegrationStatus)}`, { ns: Namespaces.Integrations }),
					};
				}

				return {
					...partialOption,
					label: value as string,
				};
			}),
		];

		if (isNull && emptyFieldLabel && counters[EMPTY_FIELD_VALUE]) {
			options.unshift(getNullishFilterOption(emptyFieldLabel, EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}
		return options;
	};

	readMore = async (): Promise<void> => {
		await this.dataStore.readMore();
	};

	read = async (): Promise<void> => {
		this.dataStore.read();
	};

	integrationsDisposer = reaction(
		() => this.dataStore.integrations,
		(integrations) => {
			const sourceDataEdges = integrations?.listIntegrations.edges ?? [];

			this.sourceData = sourceDataEdges.map((integration: IntegrationEdge): ExtendedIntegrationEdge => {
				const hostname = integration.node.hostname;
				const isHostnameLocalhost = isLocalhost(String(hostname));
				const displayHostname = isHostnameLocalhost ? null : hostname;

				const { type, sourceType, name } = integration.node;
				const collectorType = integration.node.collector?.type;

				const telemetryIntegrationType = getTelemetryIntegrationType(type, sourceType, collectorType);

				return {
					...integration,
					node: { ...integration.node, name: name ?? 'n/a', hostname: displayHostname, telemetryIntegrationType: telemetryIntegrationType },
				};
			});
		},
	);

	dispose = () => {
		this.integrationsDisposer();
	};
}
