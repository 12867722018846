import { BoolStrings } from '@/app/collectors/_common/types';

export const boolToStringErrorMessage = 'Received value should be a boolean';

export const convertBooleanToString = (value: unknown): BoolStrings => {
	if (typeof value !== 'boolean') {
		throw boolToStringErrorMessage;
	}

	return value ? BoolStrings.True : BoolStrings.False;
};
