import { IntegrationComponentDefinition, Maybe } from '@/generated/graphql';

export const isCloudCollectorDisabled = (components: Maybe<IntegrationComponentDefinition[]> | undefined): boolean => {
	if (components && (components?.length === 0 || components?.length > 2)) {
		return true;
	} else if (components?.length === 2 && components[0].environments && components[1].environments) {
		if (
			(components[0]?.environments?.length > 0 && components[1]?.environments?.length > 0) ||
			(components[0]?.environments?.length === 0 && components[1]?.environments?.length === 0)
		) {
			return true;
		}
	}

	return false;
};
