import { IntegrationFormFieldDataType } from '@/app/collectors/_common/types';

import { CreateIntegrationComponentConfiguration, CreateIntegrationInput, EnvironmentVariableType, IntegrationType } from '@/generated/graphql';
import { convertValueAccordingToFieldType } from '@/app/create-integration/_common/utils/';
import { StaticFields } from '@/app/create-integration/_common/_components/integration-details-step/integration-details-form-wrapper';

interface PrepareObjectToCreateIntegrationProps {
	data: Record<string, IntegrationFormFieldDataType>;
	collectorId?: string;
	definitionId: string;
	required: string[];
	environmentsFieldTypes: Record<string, EnvironmentVariableType | undefined>;
	type: IntegrationType;
}

export const prepareObjectToCreateIntegration = ({
	data,
	collectorId,
	definitionId,
	required,
	environmentsFieldTypes,
	type,
}: PrepareObjectToCreateIntegrationProps): CreateIntegrationInput => {
	const configurationKeys = Object.keys(data).filter((value) => value !== StaticFields.Description && value !== StaticFields.Name);

	const handleConfigurationKeys = (key: string) => {
		let value = data[key];
		const type = environmentsFieldTypes[key];
		const isRequired = required.includes(key);

		value = convertValueAccordingToFieldType(value, isRequired, type) as string | undefined;

		return {
			key,
			value,
		};
	};

	const configuration = configurationKeys
		.map(handleConfigurationKeys)
		.filter((configuration) => Boolean(configuration.value)) as CreateIntegrationComponentConfiguration[];

	return {
		name: data[StaticFields.Name] as string,
		description: data[StaticFields.Description] as string,
		collectorId,
		definitionId,
		components: [{ configuration: configuration }],
		type,
	};
};
