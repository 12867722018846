import { FiltersInput, ValueOperator } from '@/generated/graphql';

export const getTelemetryIntegrationsFiltersInput = (collectorId?: string): FiltersInput => {
	const filtersInput = {
		valueFilters: [
			{
				field: 'type',
				operator: ValueOperator.Is,
				value: 'TELEMETRY',
			},
		],
	};

	if (collectorId) {
		filtersInput.valueFilters.push({
			field: 'collector.id',
			value: collectorId,
			operator: ValueOperator.Is,
		});
	}
	return filtersInput;
};
