import { gql } from '@apollo/client';
import { FranchiseUserFragment, UserFragment } from '@/app/_common/graphql/fragments.constants';

export const ListUsers = gql`
	query ListUsers($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listUsers(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...UserFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${UserFragment}
`;

export const ListAllUsers = gql`
	query ListAllUsers($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listUsers(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					id
				}
				cursor
			}
			pageInfo {
				endCursor
				startCursor
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;

export const ListFranchiseUsers = gql`
	query ListFranchiseUsers($franchiseId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listFranchiseUsers(franchiseId: $franchiseId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...FranchiseUserFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${FranchiseUserFragment}
`;

export const RemoveUser = gql`
	mutation RemoveUser($tenantId: ID!, $userId: ID!) {
		removeUser(tenantId: $tenantId, userId: $userId) {
			... on Error {
				message
				code
				correlationId
			}
			... on User {
				id
				upn
				displayName
			}
		}
	}
`;

export const RemoveFranchiseUser = gql`
	mutation RemoveFranchiseUser($franchiseId: ID!, $userId: ID!) {
		removeFranchiseUser(franchiseId: $franchiseId, userId: $userId) {
			... on FranchiseUser {
				id
				displayName
				upn
			}

			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const ResendInvitation = gql`
	mutation ResendInvitation($tenantId: ID!, $email: String!) {
		resendInvitation(tenantId: $tenantId, email: $email) {
			... on User {
				id
				displayName
				firstName
				lastName
				upn
				lastLogin
				inviteExpirationTimestamp
				tenants {
					id
					invitedBy
					invitedTimestamp
					registrationTimestamp
					isBillingUser
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const ResendFranchiseInvitation = gql`
	mutation resendFranchiseInvitation($franchiseId: ID!, $email: String!) {
		resendFranchiseInvitation(franchiseId: $franchiseId, email: $email) {
			... on FranchiseUser {
				...FranchiseUserFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${FranchiseUserFragment}
`;

export const CancelInvitation = gql`
	mutation CancelInvitation($tenantId: ID!, $userId: String!) {
		cancelInvitation(tenantId: $tenantId, userId: $userId) {
			... on Error {
				message
				code
				correlationId
			}
			... on User {
				id
				upn
			}
		}
	}
`;

export const CancelFranchiseInvitation = gql`
	mutation CancelFranchiseInvitation($franchiseId: ID!, $userId: String!) {
		cancelFranchiseInvitation(franchiseId: $franchiseId, userId: $userId) {
			... on Error {
				message
				code
				correlationId
			}
			... on FranchiseUser {
				id
				upn
			}
		}
	}
`;
