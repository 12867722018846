export enum InvestigationDetailsTabs {
	ALERTS,
	QUERY_RESULTS,
}

export interface InvestigationDetailsQueryResultsContributor {
	label: string;
	lastActivity: Date;
}

export enum IntegrationFormValidationStatusBlockState {
	IsConfigurationDisabled = 'isConfigurationDisabled',
	NotReadyToTest = 'notReadyToTest',
	ReadyToTest = 'readyToTest',
	IsRequestPending = 'isRequestPending',
	TestFailed = 'testFailed',
	TestPassed = 'testPassed',
}

export enum IntegrationEditFormButtonsState {
	NotReadyToTest = 'notReadyToTest',
	ReadyToTest = 'readyToTest',
	IsRequestPending = 'isRequestPending',
	ReadyToSave = 'readyToSave',
}
