import { gql } from '@apollo/client';
import { CompromiseUserStatsFragment, CompromiseUserDetailsFragment } from '@/app/_common/graphql';

export const GetCompromiseStats = gql`
	query GetCompromiseStats($tenantId: ID!) {
		getCompromiseStats(tenantId: $tenantId) {
			... on CompromiseStats {
				compromisedUsersCount
				compromisedWebsitesCount
				lastRetrievedTimestamp
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetRegisteredUsers = gql`
	query GetAssignees($tenantId: ID!, $filtersInput: FiltersInput) {
		listUsers(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

export const ListCompromiseUserStats = gql`
	query ListCompromiseUserStats($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput, $sortersInput: SortersInput) {
		listCompromiseUserStats(tenantId: $tenantId, input: $input, filtersInput: $filtersInput, sortersInput: $sortersInput) {
			edges {
				node {
					...CompromiseUserStatsFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CompromiseUserStatsFragment}
`;

export const ListCompromiseUserStatsForFranchise = gql`
	query ListCompromiseUserStatsForFranchise($franchiseId: ID!, $input: Paging, $filtersInput: FiltersInput, $sortersInput: SortersInput) {
		listCompromiseUserStatsForFranchise(franchiseId: $franchiseId, input: $input, filtersInput: $filtersInput, sortersInput: $sortersInput) {
			edges {
				node {
					...CompromiseUserStatsFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CompromiseUserStatsFragment}
`;

export const ListCompromiseUserDetails = gql`
	query ListCompromiseUserDetails($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput, $sortersInput: SortersInput) {
		listCompromiseUserDetails(tenantId: $tenantId, input: $input, filtersInput: $filtersInput, sortersInput: $sortersInput) {
			edges {
				node {
					...CompromiseUserDetailsFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CompromiseUserDetailsFragment}
`;

export const ListCompromiseUserDetailsForFranchise = gql`
	query ListCompromiseUserDetailsForFranchise($franchiseId: ID!, $input: Paging, $filtersInput: FiltersInput, $sortersInput: SortersInput) {
		listCompromiseUserDetailsForFranchise(franchiseId: $franchiseId, input: $input, filtersInput: $filtersInput, sortersInput: $sortersInput) {
			edges {
				node {
					...CompromiseUserDetailsFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CompromiseUserDetailsFragment}
`;
