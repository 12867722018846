import { action, computed, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { IntegrationDefinition, IntegrationType, Query, QueryListIntegrationDefinitionsArgs, ValueOperator } from '@/generated/graphql';
import { ListIntegrationDefinitions } from '@/app/_common/graphql/queries';

export type ListIntegrationDefinitions = Pick<Query, 'listIntegrationDefinitions'>;

export class IntegrationDefinitionsDataStore extends GraphqlBaseDataStore<ListIntegrationDefinitions, QueryListIntegrationDefinitionsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			read: action,
			integrationDefinitions: computed,
		});
	}

	get integrationDefinitions(): IntegrationDefinition[] {
		return this.data?.listIntegrationDefinitions.edges.map((edge) => edge.node) || [];
	}

	read(integrationsType: IntegrationType) {
		const responseFiltersInput = {
			valueFilters: [
				{
					field: 'isResponse',
					value: 'true',
					operator: ValueOperator.Is,
				},
			],
		};

		const telemetryFiltersInput = {
			valueFilters: [
				{
					field: 'isTelemetry',
					value: 'true',
					operator: ValueOperator.Is,
				},
			],
		};

		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: integrationsType === IntegrationType.Response ? responseFiltersInput : telemetryFiltersInput,
		};

		this.query({
			query: ListIntegrationDefinitions,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
