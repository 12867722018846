import { ReactNode } from 'react';

import styles from './multiple-columns-layout.module.scss';

type Column = { id: string; fields: (JSX.Element | undefined | null)[] | null; header?: ReactNode };

interface MultipleColumnsLayoutProps {
	columns: Column[];
}

export const MultipleColumnsLayout = ({ columns }: MultipleColumnsLayoutProps) => {
	return (
		<>
			{columns.map(({ id, fields, header = null }) => {
				if (!fields?.length) {
					return null;
				}

				return (
					<div key={id} className={styles.container}>
						{header && <div>{header}</div>}
						{fields}
					</div>
				);
			})}
		</>
	);
};
