import { KeyValue } from '@progress/kendo-react-form';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Control, FieldPath, UseFormStateReturn } from 'react-hook-form/dist/types';
import { TFunction } from 'i18next';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';

export const NameField = {
	Name: 'name',
	Type: 'text',
	Label: 'createIntegrationFields.name',
	Placeholder: 'createIntegrationFields.name',
} as const;

export const DescriptionField = {
	Name: 'description',
	Type: 'text',
	Label: 'createIntegrationFields.description',
	Placeholder: 'createIntegrationFields.description',
} as const;

export const HostnameField = {
	Name: 'hostname',
	Type: 'text',
	Label: 'createIntegrationFields.hostname',
	Placeholder: 'createIntegrationFields.hostname',
} as const;

export interface IntegrationDetailsFormData {
	[NameField.Name]: string | undefined;
	[DescriptionField.Name]: string | undefined;
	[HostnameField.Name]: string | undefined;
}

export type IntegrationDetailsFormValidator = (data: IntegrationDetailsFormData) => KeyValue<string> | undefined;

export enum IntegrationDetailsFormType {
	Default = 'default',
	EditForm = 'editForm',
}

export interface BaseFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
	disabled: boolean;
	register: UseFormRegister<TFieldValues>;
	t: TFunction;
	controllerProps?: {
		field?: ControllerRenderProps<TFieldValues, TName>;
		fieldState?: ControllerFieldState;
		formState?: UseFormStateReturn<TFieldValues>;
	};
}

export interface FieldComponentProps<TFieldValues extends FieldValues> {
	disabled: boolean;
	register: UseFormRegister<TFieldValues>;
	t: TFunction;
	control?: Control<TFieldValues>;
}

export interface ControlledFieldComponentProps<TFieldValues extends FieldValues> {
	disabled: boolean;
	register: UseFormRegister<TFieldValues>;
	t: TFunction;
	control: Control<TFieldValues>;
}
