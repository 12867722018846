import { ElementType, FC, lazy, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { RouterLoader } from '@/app/_common/_components';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RootPaths } from '@/app/_common/navigation';
import { PageLayout } from '@/app/_common/_components/page-layout';
import { PrivateRoute } from '@/app/_common/_components/private-route';
import { PageNotFound } from './page-not-found/page-not-found';
import { PageNotAccess } from './page-not-access/page-not-access';
import { FeaturesFlag } from './_common/interfaces';
import { AppConfigurationViewStore } from '@/app/_common/stores/app-configuration-store/app-configuration.view-store';
import { APP_ENTRY_ROUTE } from '@/app/_common/constants/app.constants';
import ActionHistoryModule from '@/app/_features/action-history/action-history-module';
const CompromiseMonitorModule = lazy(() => import('@/app/tools/compromise-monitor/compromise-monitor-module'));
const DashboardModule = lazy(() => import('@/app/dashboards/main-dashboard/dashboards-module'));
const AlertsDashboardsModule = lazy(() => import('@/app/dashboards/alerts-dashboard/alerts-dashboards-module'));
const TelemetryDashboardModule = lazy(() => import('@/app/dashboards/telemetry-dashboard/telemetry-dashboard-module'));
const TelemetryModule = lazy(() => import('@/app/telemetry/telemetry-module'));
const InvestigationsModule = lazy(() => import('@/app/investigations/investigations-module'));
const InvestigationDetailsModule = lazy(() => import('@/app/investigation-details/investigation-details-module'));
const CreateIntegrationModule = lazy(
	() => import('@/app/create-integration/modules/create-telemetry-integration/create-telemetry-integration-module'),
);
const IntegrationDeleteModule = lazy(() => import('@/app/integration-delete/integration-delete-module'));
const AdvancedQueryModule = lazy(() => import('@/app/advanced-query/advanced-query-module'));
const CollectorsModule = lazy(() => import('@/app/collectors/collectors-module'));
const TelemetryIntegrationsModule = lazy(() => import('@/app/telemetry/integrations/telemetry-integrations-module'));
const ResponseModule = lazy(() => import('@/app/response/response-module'));
const ResponseIntegrationsModule = lazy(() => import('@/app/response/integrations/response-integrations-module'));
const CreateResponseIntegrationModule = lazy(
	() => import('@/app/create-integration/modules/create-response-integration/create-response-integration-module'),
);
const EndpointsModule = lazy(() => import('@/app/response/endpoints/endpoints-module'));
const ManagementModule = lazy(() => import('@/app/management/management-module'));
const ManagementUsersModule = lazy(() => import('@/app/_features/users/users-module'));
const ManagementActionHistoryModule = lazy(() => import('@/app/_features/action-history/action-history-module'));
const FranchiseClientsModule = lazy(() => import('@/app/franchise/clients/clients-module'));
const CreateClientModule = lazy(() => import('@/app/franchise/create-client/create-franchise-client.module'));
const ToolsModule = lazy(() => import('@/app/tools/tools-module'));
const IntegrationDetailsModule = lazy(() => import('@/app/integration-details/integration-details-module'));
const TokenVerification = lazy(() => import('@/app/_common/auth/token-verification'));
const ExpiredToken = lazy(() => import('@/app/_common/auth/expired-token'));
const LogIn = lazy(() => import('@/app/_common/auth/login'));
const SignOut = lazy(() => import('@/app/_common/auth/sign-out'));
const EditIntegrationModule = lazy(() => import('@/app/integration-edit/integration-edit-module'));

interface PrivateRouteProps {
	path: string;
	key: string;
	component: ElementType;
	feature?: FeaturesFlag;
	props?: object;
}

const privateRoutes: PrivateRouteProps[] = [
	{
		key: 'franchise',
		path: RootPaths.FRANCHISE,
		component: FranchiseClientsModule,
	},
	{
		key: 'franchiseActionHistory',
		path: RootPaths.FRANCHISE_ACTION_HISTORY,
		component: ActionHistoryModule,
		props: { isFranchise: true },
	},
	{
		key: 'franchiseClients',
		path: RootPaths.FRANCHISE_CLIENTS,
		component: FranchiseClientsModule,
	},
	{
		key: 'createFranchiseClients',
		path: RootPaths.CREATE_FRANCHISE_CLIENT,
		component: CreateClientModule,
	},
	{
		key: 'franchiseTeam',
		path: RootPaths.FRANCHISE_TEAM,
		component: () => <ManagementUsersModule isFranchise />,
	},
	{
		key: 'dashboards',
		path: RootPaths.DASHBOARDS,
		component: DashboardModule,
	},
	{
		key: 'alertsDashboard',
		path: RootPaths.ALERTS_DASHBOARDS,
		component: AlertsDashboardsModule,
	},
	{
		key: 'telemetryDashboard',
		path: RootPaths.TELEMETRY_DASHBOARD,
		component: TelemetryDashboardModule,
	},
	{
		key: 'telemetry',
		path: RootPaths.TELEMETRY,
		component: TelemetryModule,
	},
	{
		key: 'investigations',
		path: RootPaths.INVESTIGATIONS,
		component: InvestigationsModule,
	},
	{
		key: 'investigationDetails',
		path: RootPaths.INVESTIGATION_DETAILS,
		component: InvestigationDetailsModule,
	},
	{
		key: 'advancedQuery',
		path: RootPaths.ADVANCED_QUERY,
		component: AdvancedQueryModule,
	},
	{
		key: 'collectors',
		path: RootPaths.COLLECTORS,
		component: CollectorsModule,
	},
	{
		key: 'integrations',
		path: RootPaths.TELEMETRY_INTEGRATIONS,
		component: TelemetryIntegrationsModule,
	},
	{
		key: 'createTelemetryIntegration',
		path: RootPaths.CREATE_TELEMETRY_INTEGRATION,
		component: CreateIntegrationModule,
	},
	{
		key: 'editTelemetryIntegration',
		path: RootPaths.EDIT_TELEMETRY_INTEGRATION,
		component: EditIntegrationModule,
	},
	{
		key: 'deleteIntegrationInCollector',
		path: RootPaths.INTEGRATION_DELETE_IN_COLLECTOR,
		component: IntegrationDeleteModule,
	},
	{
		key: 'integrationConfiguration',
		path: RootPaths.INTEGRATION_CONFIGURATION,
		component: IntegrationDetailsModule,
	},
	{
		key: 'createResponseIntegration',
		path: RootPaths.CREATE_RESPONSE_INTEGRATION,
		component: CreateResponseIntegrationModule,
	},
	{
		key: 'telemetryIntegrationDetails',
		path: RootPaths.TELEMETRY_INTEGRATION_DETAILS,
		component: IntegrationDetailsModule,
	},
	{
		key: 'responseIntegrationDetails',
		path: RootPaths.RESPONSE_INTEGRATION_DETAILS,
		component: IntegrationDetailsModule,
	},
	{
		key: 'deleteIntegration',
		path: RootPaths.INTEGRATION_DELETE,
		component: IntegrationDeleteModule,
	},
	{
		key: 'response',
		path: RootPaths.RESPONSE,
		component: ResponseModule,
	},
	{
		key: 'responseIntegrations',
		path: RootPaths.RESPONSE_INTEGRATIONS,
		component: ResponseIntegrationsModule,
	},
	{
		key: 'endpoints',
		path: RootPaths.RESPONSE_ENDPOINTS,
		component: EndpointsModule,
	},
	{
		key: 'management',
		path: RootPaths.MANAGEMENT,
		component: ManagementModule,
	},
	{
		key: 'managementUsers',
		path: RootPaths.MANAGEMENT_USERS,
		component: ManagementUsersModule,
	},
	{
		key: 'managementActionHistory',
		path: RootPaths.MANAGEMENT_ACTION_HISTORY,
		component: ManagementActionHistoryModule,
	},
	{
		key: 'tools',
		path: RootPaths.TOOLS,
		component: ToolsModule,
	},
	{
		key: 'compromiseMonitor',
		path: RootPaths.COMPROMISE_MONITOR,
		component: CompromiseMonitorModule,
	},
];

export const AppRoutes: FC = observer(() => {
	const { pathname } = useLocation();
	const appConfigurationViewStore = useInstance(AppConfigurationViewStore);

	const featuresPrivateRoutes = privateRoutes.map(({ key, path, component: Component, feature, props }) => {
		return (
			<Route
				key={key}
				path={path}
				element={
					<PrivateRoute access={appConfigurationViewStore.userHasAccess(feature)}>
						<Component {...props} />
					</PrivateRoute>
				}
			/>
		);
	});

	return (
		<PageLayout>
			<Suspense fallback={<RouterLoader pathname={pathname} />}>
				<Routes>
					<Route path="/" element={<Navigate replace to={APP_ENTRY_ROUTE} />} />
					{featuresPrivateRoutes}
					<Route key="login" path={RootPaths.LOGIN} element={<LogIn />} />
					<Route key="signout" path={RootPaths.SIGN_OUT} element={<SignOut />} />
					<Route key="verifyToken" path={RootPaths.VERIFICATION} element={<TokenVerification />} />
					<Route key="expiredToken" path={RootPaths.EXPIRED_TOKEN} element={<ExpiredToken />} />
					<Route path={RootPaths.PAGE_NOT_ACCESS} element={<PageNotAccess />} />
					<Route
						path="*"
						element={
							<PrivateRoute>
								<PageNotFound />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Suspense>
		</PageLayout>
	);
});
