import { makeAutoObservable } from 'mobx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { ValidateIntegrationViewStore } from '@/app/create-integration/_common/stores';
import { IntegrationFormData, ValidateIntegrationResult } from '@/app/create-integration/_common/interfaces';
import { IntegrationType } from '@/generated/graphql';
import { IntegrationEditFormButtonsState, IntegrationFormValidationStatusBlockState } from '@/app/_common/types';

interface State {
	isConfigurationSwitchChecked: boolean;
	isFormDirty: boolean;
	isFormValid: boolean;
	isSubmitting: boolean;
}

const INITIAL_STATE: State = {
	isConfigurationSwitchChecked: false,
	isFormDirty: false,
	isFormValid: false,
	isSubmitting: false,
};

export class IntegrationEditPageViewStore {
	private state: State = INITIAL_STATE;
	private validateIntegrationViewStore = injectInterface(this, ValidateIntegrationViewStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get isConfigurationSwitchChecked(): boolean {
		return this.state.isConfigurationSwitchChecked;
	}

	get validationResult(): ValidateIntegrationResult | null {
		return this.validateIntegrationViewStore.validationResult;
	}

	get isTestPassed(): boolean {
		return this.validationResult === ValidateIntegrationResult.SUCCESS;
	}

	get isTestFailed(): boolean {
		return this.validationResult === ValidateIntegrationResult.FAILED;
	}

	get isTesting(): boolean {
		return this.validationResult === ValidateIntegrationResult.PENDING;
	}

	get isFormDirty(): boolean {
		return this.state.isFormDirty;
	}

	get isFormValid(): boolean {
		return this.state.isFormValid;
	}

	get isSubmitting(): boolean {
		return this.state.isSubmitting;
	}

	get canSaveDynamicFields(): boolean {
		return this.isConfigurationSwitchChecked && this.isTestPassed && !this.isFormDirty;
	}

	get canSaveStaticFields(): boolean {
		return !this.isConfigurationSwitchChecked && this.isFormValid && !this.isFormDirty;
	}

	get canSaveForm(): boolean {
		return this.canSaveStaticFields || this.canSaveDynamicFields;
	}

	get buttonsBlockState(): IntegrationEditFormButtonsState {
		const canNotTestForm = this.isConfigurationSwitchChecked && !this.isFormValid;
		const canTestForm = this.isConfigurationSwitchChecked && this.isFormValid;

		if (this.isTesting || this.isSubmitting) {
			return IntegrationEditFormButtonsState.IsRequestPending;
		}
		if (this.canSaveForm) {
			return IntegrationEditFormButtonsState.ReadyToSave;
		}
		if (canNotTestForm) {
			return IntegrationEditFormButtonsState.NotReadyToTest;
		}
		if (canTestForm) {
			return IntegrationEditFormButtonsState.ReadyToTest;
		}
		return IntegrationEditFormButtonsState.ReadyToSave;
	}

	get validationStatusBlockState(): IntegrationFormValidationStatusBlockState {
		if (!this.isConfigurationSwitchChecked) {
			return IntegrationFormValidationStatusBlockState.IsConfigurationDisabled;
		}
		if (this.isTesting) {
			return IntegrationFormValidationStatusBlockState.IsRequestPending;
		}
		if (this.canSaveDynamicFields) {
			return IntegrationFormValidationStatusBlockState.TestPassed;
		}
		if (this.isTestFailed) {
			return IntegrationFormValidationStatusBlockState.TestFailed;
		}
		if (!this.isFormValid) {
			return IntegrationFormValidationStatusBlockState.NotReadyToTest;
		}
		if (this.isFormValid) {
			return IntegrationFormValidationStatusBlockState.ReadyToTest;
		}
		return IntegrationFormValidationStatusBlockState.IsConfigurationDisabled;
	}

	toggleIsConfigurationSwitchChecked() {
		this.state.isConfigurationSwitchChecked = !this.state.isConfigurationSwitchChecked;
	}

	setIsFormValid(isFormValid: boolean) {
		this.state.isFormValid = isFormValid;
	}

	setIsFormDirty(isFormDirty: boolean) {
		this.state.isFormDirty = isFormDirty;
	}

	validateIntegration = async (formValues: IntegrationFormData, definitionId: string, integrationType?: IntegrationType): Promise<void> => {
		if (!definitionId || !integrationType) {
			return;
		}

		this.validateIntegrationViewStore.validateIntegration(formValues, integrationType, definitionId);
	};
}
