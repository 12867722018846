import { makeObservable, action } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { CreateIntegrationInput, Mutation, MutationCreateIntegrationArgs } from '@/generated/graphql';
import { CreateIntegration } from '@/app/_common/graphql/queries';

export class CreateIntegrationDataStore extends GraphqlBaseDataStore<Mutation, MutationCreateIntegrationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			create: action,
		});
	}

	async create(args: CreateIntegrationInput) {
		const variables = {
			createIntegrationRequest: args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationCreateIntegrationArgs>({
			mutation: CreateIntegration,
			variables,
		});

		return response;
	}
}
