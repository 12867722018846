import { UpdatedBy } from '@/generated/graphql';

export const getUpdatedByLabels = (creatorEmail: string | undefined, updatedBy: UpdatedBy[], defaultLabel: string): string[] => {
	const updatedByLabelList = updatedBy.map((updatedBy) => updatedBy.requesterUser?.upn ?? defaultLabel);

	updatedByLabelList.unshift(creatorEmail ?? defaultLabel);

	const uniqueUpdatedByEmails = Array.from(new Set(updatedByLabelList));

	return uniqueUpdatedByEmails;
};
