import { makeAutoObservable, reaction } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FranchiseLogoDataStore } from './franchise-logo.data-store';
import { AuthStore, ThemeStore } from '@/app/_common/stores';
import { FRANCHISE_CONTAINER } from '@/app/_common/constants';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import logoSamuraiBlack from '@/assets/images/samurai-black-logo.svg';
import logoSamuraiWhite from '@/assets/images/samurai-white-logo.svg';

interface State {
	imageSrc?: string;
	isBlobURLValid: boolean;
}

export class PageLogoViewStore {
	private franchiseLogoDataStore = injectInterface(this, FranchiseLogoDataStore);
	private authStore = injectInterface(this, AuthStore);
	private themeStore = injectInterface(this, ThemeStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		makePersistable(this.state, {
			name: 'pageLogo',
			properties: ['imageSrc'],
			storage: window.localStorage,
		});
	}

	private state: State = {
		imageSrc: undefined,
		isBlobURLValid: false,
	};

	get isBlobURLValid() {
		return this.state.isBlobURLValid;
	}

	get imageSrc() {
		return this.state.imageSrc;
	}

	get franchiseLogo() {
		return this.franchiseLogoDataStore.franchiseLogo;
	}

	get loading(): boolean {
		return this.franchiseLogoDataStore.loading;
	}

	get error() {
		return this.franchiseLogoDataStore.error;
	}

	pageLogoDisposer = reaction(
		() => [this.authStore.franchiseId, this.themeStore.isDarkTheme],
		() => {
			if (!this.authStore.franchiseId) return;

			const franchiseLogoFileName = this.themeStore.isDarkTheme
				? this.authStore.franchise?.darkModeLogoFileName
				: this.authStore.franchise?.lightModeLogoFileName;

			if (franchiseLogoFileName) {
				try {
					this.readLogo(FRANCHISE_CONTAINER, `${this.authStore.franchiseId}/${franchiseLogoFileName}`);
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error('Error occurred during fetching franchise logo:', error);
					this.setDefaultSamuraiLogo();
				}
			} else {
				this.setDefaultSamuraiLogo();
			}
		},
	);

	franchiseLogoDisposer = reaction(
		() => this.franchiseLogo,
		(franchiseLogo) => {
			if (franchiseLogo) {
				const imageObjectURL = URL.createObjectURL(franchiseLogo);
				this.setImageSrc(imageObjectURL);
				if (imageObjectURL) {
					this.validateBlobURL(imageObjectURL);
				} else {
					this.setIsBlobURLValid(false);
				}
			}
		},
	);

	private setDefaultSamuraiLogo() {
		const defaultSamuraiLogo = this.themeStore.isDarkTheme ? logoSamuraiWhite : logoSamuraiBlack;
		this.setImageSrc(defaultSamuraiLogo);
		this.setIsBlobURLValid(true);
	}

	private validateBlobURL = async (imageObjectURL: string) => {
		try {
			const response = await fetch(imageObjectURL);
			if (response.ok) {
				this.setIsBlobURLValid(true);
			} else {
				throw new Error('Blob URL is not valid');
			}
		} catch (error) {
			this.setIsBlobURLValid(false);
		}
	};

	readLogo(container?: string, key?: string) {
		this.franchiseLogoDataStore.read(container, key);
	}

	setImageSrc(imageSrc: string) {
		this.state.imageSrc = imageSrc;
	}

	dispose() {
		this.pageLogoDisposer();
		this.franchiseLogoDisposer();
		stopPersisting(this.state);
	}

	setIsBlobURLValid(isBlobURLValid: boolean) {
		this.state.isBlobURLValid = isBlobURLValid;
	}
}
