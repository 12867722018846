import { makeAutoObservable } from 'mobx';

import { UsersDataStore } from '@/app/_common/stores/users-store/users.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FranchiseUsersDataStore } from '@/app/_common/stores/franchise-users-store';
import { AuthStore } from '@/app/_common/stores';
import { getAssigneesOptions } from '@/app/_common/_components/forms/utils/get-assignee-options';

export class AssigneeSelectViewStore {
	private authStore = injectInterface(this, AuthStore);
	private assigneeDataStore = injectInterface(this, UsersDataStore);
	private franchiseAssigneeDataStore = injectInterface(this, FranchiseUsersDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		this.assigneeDataStore.read();

		if (this.authStore.isCurrentUserFranchiseUser) {
			this.franchiseAssigneeDataStore.read();
		}
	}

	get usersAssigneeData() {
		return getAssigneesOptions(this.assigneeDataStore.users);
	}

	get franchiseUsersAssigneeData() {
		return getAssigneesOptions(this.franchiseAssigneeDataStore.franchiseUsers);
	}

	get loading(): boolean {
		return this.assigneeDataStore.loading || this.franchiseAssigneeDataStore.loading;
	}
}
