import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { makeAutoObservable } from 'mobx';
import { CollectorType, EnvironmentVariable, EnvironmentVariableType, IntegrationDefinition } from '@/generated/graphql';

import { ChooseIntegrationStepViewStore } from './choose-integration-step.view-store';
import { StaticFields } from '@/app/create-integration/_common/_components/integration-details-step/integration-details-form-wrapper';
import { IntegrationDetailsFormDisabledProp } from '@/app/_common/types';

interface Error {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

type Environments = Record<string, EnvironmentVariable>;
type EnvironmentsFieldTypes = Record<string, EnvironmentVariableType | undefined>;

interface State {
	definition?: IntegrationDefinition;
	errors: Error;
	formDisabledState: IntegrationDetailsFormDisabledProp;
}

const initialState: State = {
	errors: {},
	definition: undefined,
	formDisabledState: IntegrationDetailsFormDisabledProp.Enabled,
};

export class IntegrationDetailsStepViewStore {
	private state: State = initialState;

	private chooseIntegrationStepViewStore = injectInterface(this, ChooseIntegrationStepViewStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get environments(): Environments[] {
		const environments = this.getEnvironmentsBasedOnCollectorType();

		if (!environments) {
			return [];
		}

		return Object.values(environments).map((environment) => ({ [environment.name]: environment }));
	}

	get environmentsObject(): Environments {
		return this.environments.reduce((prev: Environments, curr: Environments) => Object.assign(prev, curr), {});
	}

	get errors(): Error {
		return this.state.errors;
	}

	get formDisabledState(): IntegrationDetailsFormDisabledProp {
		return this.state.formDisabledState;
	}

	get environmentsFieldTypes(): EnvironmentsFieldTypes {
		const fieldTypesObject = {} as EnvironmentsFieldTypes;

		const environmentsNames = this.environmentsObject ? Object.keys(this.environmentsObject) : [];
		environmentsNames.forEach((name) => {
			fieldTypesObject[name] = this.environmentsObject[name].type;
		});
		return fieldTypesObject;
	}

	getRequiredEnvironments(): string[] {
		const environmentsNames = this.environmentsObject ? Object.keys(this.environmentsObject) : [];
		const requiredFieldNames = environmentsNames.filter((name) => {
			return this.environmentsObject[name]?.required?.mandatory;
		});
		requiredFieldNames.push(StaticFields.Name);
		return requiredFieldNames;
	}

	setFormDisabledState = (value: IntegrationDetailsFormDisabledProp) => {
		this.state.formDisabledState = value;
	};

	private getEnvironmentsBasedOnCollectorType(): EnvironmentVariable[] | undefined | null {
		if (this.chooseIntegrationStepViewStore.definition?.collectorType === CollectorType.Cloud) {
			return this.getCloudCollectorEnvironments();
		}

		if (
			this.chooseIntegrationStepViewStore.definition?.collectorType === CollectorType.Local &&
			this.chooseIntegrationStepViewStore.components &&
			this.chooseIntegrationStepViewStore.components.length === 1
		) {
			return this.chooseIntegrationStepViewStore.components[0]?.environments;
		}

		return undefined;
	}

	private getCloudCollectorEnvironments(): EnvironmentVariable[] | undefined | null {
		if (this.chooseIntegrationStepViewStore.components?.length === 1) {
			return this.chooseIntegrationStepViewStore.components[0].environments;
		} else if (this.chooseIntegrationStepViewStore.components?.length === 2) {
			const firstComponentEnv = this.chooseIntegrationStepViewStore.components[0].environments;
			const secondComponentEnv = this.chooseIntegrationStepViewStore.components[1].environments;

			if (firstComponentEnv && firstComponentEnv.length > 0 && (!secondComponentEnv || (secondComponentEnv && secondComponentEnv.length === 0))) {
				return firstComponentEnv;
			} else if (
				secondComponentEnv &&
				secondComponentEnv.length > 0 &&
				(!firstComponentEnv || (firstComponentEnv && firstComponentEnv.length === 0))
			) {
				return secondComponentEnv;
			}
		}

		return undefined;
	}

	setErrors(errors: Error): void {
		this.state.errors = errors;
	}
}
