import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import classNames from 'classnames';

import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/stores/alerts-details-navigation.view-store';

import { AlertTypes } from '@/app/_common/constants';
import { SingleAlertContent } from './single-alert-content/single-alert-content';
import { BoostAlertList } from './boost-alert-list/boost-alert-list';
import { VendorAlertEvidenceList } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/vendor-alert-evidence-list/vendor-alert-evidence-list';
import { AlertRecommendation } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/alert-recommendation/alert-recommendation';
import { getAlertVendorKey } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { AlertVendorKey } from '@/app/_common/_components/details-view/types';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';

import styles from './summary-main-content.module.scss';

interface SummaryMainContentProps {
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
	alertEventSource?: string;
	recommendation?: string | null;
}

export const SummaryMainContent = observer(
	({ threatIntelligenceMatches, threatIntelligenceDescriptions, alertEventSource, recommendation }: SummaryMainContentProps) => {
		const alertDetailsNavigationStore = useInstance(AlertsDetailsNavigationViewStore);
		const alertRef = useRef<HTMLDivElement | null>(null);
		const vendorKey = getAlertVendorKey(alertEventSource);

		if (
			vendorKey === AlertVendorKey.CROWDSTRIKE_FALCON ||
			vendorKey === AlertVendorKey.GOOGLE_WORKSPACE ||
			vendorKey === AlertVendorKey.MICROSOFT_DEFENDER ||
			vendorKey === AlertVendorKey.CISCO_MERAKI ||
			vendorKey === AlertVendorKey.PALO_ALTO_NGFW ||
			vendorKey === AlertVendorKey.FORTINET_FORTIGATE ||
			vendorKey === AlertVendorKey.OKTA_WORKFORCE ||
			vendorKey === AlertVendorKey.SECIOSS_SECIOSSLINK ||
			vendorKey === AlertVendorKey.TREND_MICRO
		) {
			return (
				<div className={classNames(styles.alertDetails, styles.vendorAlert)}>
					<AlertRecommendation recommendation={recommendation} vendorKey={vendorKey} />
					<VendorAlertEvidenceList vendorKey={vendorKey} />
				</div>
			);
		}

		if (alertDetailsNavigationStore.alertType === AlertTypes.BOOST) {
			return (
				<div className={classNames(styles.alertDetails, styles.boostAlert)} ref={alertRef}>
					<BoostAlertList
						signatures={alertDetailsNavigationStore.signatures}
						threatIntelligenceMatches={threatIntelligenceMatches}
						threatIntelligenceDescriptions={threatIntelligenceDescriptions}
					/>
				</div>
			);
		}

		return (
			<div className={styles.alertDetails}>
				{alertDetailsNavigationStore.singleAlertSessions.length > 0 && (
					<SingleAlertContent
						sessions={alertDetailsNavigationStore.singleAlertSessions}
						importantColumns={alertDetailsNavigationStore.singleAlertImportantFields}
						alertDetailsId={alertDetailsNavigationStore.alertDetailsId}
						threatIntelligenceMatches={threatIntelligenceMatches}
						threatIntelligenceDescriptions={threatIntelligenceDescriptions}
					/>
				)}
			</div>
		);
	},
);
