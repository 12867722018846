import { useInstance } from 'react-ioc';
import { Reference, useSubscription } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { CoreInvestigationFragment, GraphqlClient } from '@/app/_common/graphql';
import { AuthStore } from '@/app/_common/stores';
import {
	Investigation,
	InvestigationEdge,
	Subscription,
	SubscriptionInvestigationCreatedArgs,
	SubscriptionInvestigationUpdatedArgs,
} from '@/generated/graphql';
import { InvestigationTypename } from '@/app/_common/constants';
import { InvestigationCreatedSubscription, InvestigationUpdatedSubscription } from '@/app/_common/graphql/queries';

export const mergeInvestigationCreateWithCache = (client: GraphqlClient, data?: Investigation) => {
	if (data) {
		client.cache.modify({
			fields: {
				listInvestigations(existingInvestigationRefs: Reference | StoreObject, { readField }) {
					const node = client.cache.writeFragment({
						fragment: CoreInvestigationFragment,
						data,
					});
					const edges = readField<InvestigationEdge[]>('edges', existingInvestigationRefs);

					const exists = edges?.some(
						(ref: InvestigationEdge) => data.__typename === InvestigationTypename.Investigation && readField('id', ref.node) === data.id,
					);

					if (!node || exists) {
						return existingInvestigationRefs;
					}

					const newEdge = {
						node,
						__typename: InvestigationTypename.InvestigationEdge,
						cursor: '',
					};

					return {
						...existingInvestigationRefs,
						edges: [...(edges || []), newEdge],
					};
				},
			},
		});
	}
};

export const mergeInvestigationUpdateWithCache = (client: GraphqlClient, data?: Investigation) => {
	if (data) {
		const identifier = client.cache.identify(data);

		client.cache.modify({
			id: identifier,
			fields: {
				timestamp() {
					return data.timestamp;
				},
				last_updated() {
					return data.last_updated;
				},
				tenantId() {
					return data.tenantId;
				},
				type() {
					return data.type;
				},
				priority() {
					return data.priority;
				},
				status() {
					return data.status;
				},
				name() {
					return data.name;
				},
				assignee() {
					return data.assignee;
				},
				closedDetails() {
					return data.closedDetails;
				},
				queryResults() {
					return data.queryResults;
				},
			},
		});
	}
};

export const useInvestigationsSubscription = () => {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);

	useSubscription<Subscription, SubscriptionInvestigationCreatedArgs>(InvestigationCreatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeInvestigationCreateWithCache(graphqlClient, subscriptionData?.data?.investigationCreated);
		},
	});

	useSubscription<Subscription, SubscriptionInvestigationUpdatedArgs>(InvestigationUpdatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeInvestigationUpdateWithCache(graphqlClient, subscriptionData?.data?.investigationUpdated);
		},
	});
};
