import { AlertTypename, InvestigationTypename } from '@/app/_common/constants';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { SetAlertsState } from '@/app/_common/graphql/queries';
import {
	MutationSetAlertsStateArgs,
	Query,
	QueryListUsersArgs,
	Mutation,
	AlertAssignmentCount,
	SetAlertsStateAction,
	AlertEdge,
} from '@/generated/graphql';
import { gql, Reference } from '@apollo/client';
import { action, makeObservable } from 'mobx';
import { StoreObject } from '@apollo/client/utilities';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

export class AlertsStateActionsDataStore extends GraphqlBaseDataStore<Query, QueryListUsersArgs> {
	constructor() {
		super();
		makeObservable(this, {
			update: action,
			updateInvestigationDetails: action,
		});
	}

	async update(args: Omit<MutationSetAlertsStateArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationSetAlertsStateArgs>({
			mutation: SetAlertsState,
			variables,
			update(cache, { data }) {
				if (!data?.setAlertsState || data?.setAlertsState.__typename !== AlertTypename.Alerts) {
					return;
				}

				const alerts = data?.setAlertsState?.alerts || [];
				const alertsIds = alerts?.map(({ id }) => id);

				const updateAlertCount = (alertAssignment: AlertAssignmentCount, action: SetAlertsStateAction) => {
					if (action === SetAlertsStateAction.Dismiss) {
						return {
							...alertAssignment,
							unassigned: alertAssignment.unassigned - alertsIds.length,
							dismissed: alertAssignment.dismissed + alertsIds.length,
						};
					}
					return {
						...alertAssignment,
						unassigned: alertAssignment.unassigned + alertsIds.length,
						dismissed: alertAssignment.dismissed - alertsIds.length,
					};
				};

				if (!alertsIds.length) {
					return;
				}

				cache.modify({
					fields: {
						getAlertCount(existingAlertCount: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) {
							const assignment = readField('assignment', existingAlertCount) as AlertAssignmentCount;

							return {
								...(existingAlertCount || {}),
								assignment: updateAlertCount(assignment, args.action),
							};
						},
						listAlerts(existingAlertRefs: Reference | StoreObject, { readField }) {
							const edges = readField('edges', existingAlertRefs) as AlertEdge[];

							return {
								...(existingAlertRefs || {}),
								edges: edges?.map((edgeRef) => {
									const alertId = readField('id', edgeRef.node);
									if (typeof alertId === 'string' && alertsIds.includes(alertId)) {
										const alert = {
											__typename: AlertTypename.Alert,
											id: alertId,
										};

										const updatedAlert = alerts.find(({ id }) => id === alertId);

										const updatedAlertRef = cache.writeFragment({
											id: cache.identify(alert),
											data: {
												...updatedAlert,
											},
											fragment: gql`
												# eslint-disable-next-line @graphql-eslint/no-unused-fragments
												fragment UpdatedAlert on Alert {
													state {
														alertState
														requesterUser {
															id
															upn
														}
														timestamp
													}
													investigationSummary {
														id
													}
												}
											`,
										});

										return {
											...edgeRef,
											node: updatedAlertRef,
										};
									}
									return edgeRef;
								}),
							};
						},
					},
				});
			},
		});

		return response;
	}

	async updateInvestigationDetails(args: Omit<MutationSetAlertsStateArgs, 'tenantId'>, investigationId: string) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationSetAlertsStateArgs>({
			mutation: SetAlertsState,
			variables,
			update(cache, { data }) {
				if (!data?.setAlertsState || data?.setAlertsState.__typename !== AlertTypename.Alerts) {
					return;
				}

				const alerts = data?.setAlertsState?.alerts || [];
				const alertsIds = alerts?.map(({ id }) => id);

				if (!alertsIds.length) {
					return;
				}

				const identifier = cache.identify({
					__typename: InvestigationTypename.Investigation,
					id: investigationId,
				});

				cache.modify({
					id: identifier,
					fields: {
						alerts(existingAlerts, { readField }) {
							return existingAlerts.filter((alertRef: { _ref: string }) => {
								const alertId = readField('id', alertRef) as string;

								return !alertsIds.includes(alertId);
							});
						},
					},
				});
			},
		});

		return response;
	}
}
