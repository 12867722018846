import { RootPaths } from '@/app/_common/navigation';
import { getPathWithId } from '@/app/_common/utils';
import { Integration } from '@/generated/graphql';

const COLLECTOR_ID_PARAM = ':collectorId';
const INTEGRATION_ID_PARAM = ':integrationId';

export const getIntegrationConfigPath = (integrationNode: Integration, path: RootPaths): string => {
	const pathWithCollectorId = getPathWithId(path, integrationNode.collector?.id ?? '', COLLECTOR_ID_PARAM);
	const integrationPath = getPathWithId(pathWithCollectorId, integrationNode.id, INTEGRATION_ID_PARAM);

	return integrationPath;
};
