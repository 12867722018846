import { GetFranchiseAssignees } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Query, QueryListFranchiseUsersArgs } from '@/generated/graphql';
import { action, computed, makeObservable } from 'mobx';

export class FranchiseUsersDataStore extends GraphqlBaseDataStore<Query, QueryListFranchiseUsersArgs> {
	constructor() {
		super();
		makeObservable(this, {
			franchiseUsers: computed,
			read: action,
		});
	}

	get franchiseUsers() {
		return this.data?.listFranchiseUsers?.edges.filter(({ node }) => node.registrationTimestamp);
	}

	read() {
		const franchiseId = this.authStore.franchiseId;

		const variables: QueryListFranchiseUsersArgs = { franchiseId: franchiseId };
		this.query({
			query: GetFranchiseAssignees,
			variables,
			fetchPolicy: 'cache-and-network',
		});
	}
}
