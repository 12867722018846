import { JSXElementConstructor, ReactElement, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { FieldRenderProps } from '@progress/kendo-react-form';

import { FormDropdown, FormDropdownProps } from '@/app/_common/_components/form-components/form-dropdown';
import { assigneeItemRender } from '@/app/investigation-details/_common/utils/dropdown-render-utils';
import { AuthStore, ThemeStore } from '@/app/_common/stores';

import { ListItemProps } from '@progress/kendo-react-dropdowns';

export const AssigneeDropdown = observer((props: FieldRenderProps & FormDropdownProps) => {
	const authStore = useInstance(AuthStore);
	const themeStore = useInstance(ThemeStore);

	const handleItemRender = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(li: ReactElement<HTMLLIElement, string | JSXElementConstructor<any>>, itemProps: ListItemProps) =>
			assigneeItemRender(li, itemProps, themeStore.theme === 'dark', authStore.userId, true),
		[authStore.userId, themeStore.theme],
	);

	return <FormDropdown {...props} itemRender={handleItemRender} />;
});
