import { FC } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import i18n from '@/translations/i18n';

import { Tooltip } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { AuthStore } from '@/app/_common/stores';

import { MenuHeader, PanelBarComponent } from './_common';
import { menuItems } from './_common/client-menu-items';

import styles from './client-sidebar-navigation-list.module.scss';

const OFFSET_RIGHT = 20;
const TOOLTIP_ID = 'client-sidebar-tooltip';

interface SidebarNavigationListProps {
	theme: string;
	drawerOpened: boolean;
}

export const ClientSidebarNavigationList: FC<SidebarNavigationListProps> = observer(({ drawerOpened }) => {
	const authStore = useInstance(AuthStore);

	const { isCurrentUserFranchiseUser } = authStore;
	const isFranchiseUserWithTenants = Boolean(isCurrentUserFranchiseUser && authStore.tenants?.length);
	const isFranchiseUserWithSelectedTenant = Boolean(isFranchiseUserWithTenants && authStore.currentTenantId);
	const isSidebarDisabled = isCurrentUserFranchiseUser && !isFranchiseUserWithSelectedTenant;

	return (
		<div data-tip data-for={TOOLTIP_ID}>
			{isSidebarDisabled && (
				<Tooltip
					tooltipId={TOOLTIP_ID}
					tooltipContent={i18n.t('clientSidebar.tooltip', { ns: Namespaces.Sidebar })}
					place="right"
					offsetRight={OFFSET_RIGHT}
				/>
			)}
			{isCurrentUserFranchiseUser && (
				<MenuHeader
					disabled={isSidebarDisabled}
					drawerOpened={drawerOpened}
					title={i18n.t('clientSidebar.title', { ns: Namespaces.Sidebar })}
					Icon={BusinessCenterIcon}
					iconStyle={styles.clientIcon}
				/>
			)}
			<PanelBarComponent
				drawerOpened={drawerOpened}
				menuItems={menuItems}
				selectedStyles={styles.selected}
				selectedBorderStyles={styles.selectedBorder}
				disabled={isSidebarDisabled}
			/>
		</div>
	);
});
