import { useInstance } from 'react-ioc';
import { DocumentNode, Reference, useSubscription } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { AuthStore } from '@/app/_common/stores';
import { GraphqlClient } from '@/app/_common/graphql';
import { DeletedResourceResult, Query, Subscription, SubscriptionResourceDeletedArgs } from '@/generated/graphql';

type ListProperties<T> = {
	[K in keyof T as K extends `list${string}` ? K : never]: T[K];
};
type ResourceConnection = Exclude<Query[keyof ListProperties<Query>], Error>;
type MapEdges<U> = U extends { edges: object[] } ? U['edges'] : never;
type ResourceEdge = MapEdges<ResourceConnection>[number];

export function mergeResourceDeletedWithCache(client: GraphqlClient, typename: string, key: string, data?: DeletedResourceResult) {
	if (data?.__typename === typename && 'id' in data) {
		client.cache.modify({
			fields: {
				[key](existingIntegrationsRefs: Reference | StoreObject, { readField }) {
					const edges = readField<ResourceEdge[]>('edges', existingIntegrationsRefs);

					return {
						...(existingIntegrationsRefs || {}),
						edges: [...(edges || []).filter((edge) => readField('id', edge.node) !== data.id)],
					};
				},
			},
		});
	}
}

export function useResourceDeletedSubscription(query: DocumentNode, typename: string, key: string) {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);

	useSubscription<Subscription, SubscriptionResourceDeletedArgs>(query, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeResourceDeletedWithCache(graphqlClient, typename, key, subscriptionData?.data?.resourceDeleted);
		},
	});
}
