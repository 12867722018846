import { IntegrationFormFieldDataType } from '@/app/collectors/_common/types';
import { EnvironmentVariableType } from '@/generated/graphql';
import { handleBoolType } from './handle-bool-type';

export const convertValueAccordingToFieldType = (value: IntegrationFormFieldDataType, isRequired: boolean, type?: EnvironmentVariableType) => {
	if (type === EnvironmentVariableType.Boolean) {
		return handleBoolType(value, isRequired);
	}

	return value;
};
