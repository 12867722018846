import { action, computed, makeObservable } from 'mobx';

import { GetCollectors } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Query, QueryListCollectorsArgs } from '@/generated/graphql';

export type ListCollectors = Pick<Query, 'listCollectors'>;

export class CollectorsDataStore extends GraphqlBaseDataStore<ListCollectors, QueryListCollectorsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			collectors: computed,
			read: action,
		});

		this.read();
	}

	get collectors() {
		return this.data?.listCollectors?.edges ?? [];
	}

	read() {
		const variables = {
			tenantId: this.authStore.currentTenantId,
		};

		this.query({
			query: GetCollectors,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}
}
