import { action, makeObservable } from 'mobx';
import { Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { CreateInvestigation, CreateInvestigationWithQueryResults } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { InvestigationAlertSummary, InvestigationEdge, Mutation, MutationCreateInvestigationArgs } from '@/generated/graphql';
import { InvestigationTypename } from '@/app/_common/constants';
import { CoreInvestigationFragment } from '@/app/_common/graphql';
import { UpdateListAlertsCacheAlertData } from '@/app/advanced-query/_components/advanced-query-subscriptions/types';
import { updateListAlertsCache } from '@/app/advanced-query/_components/advanced-query-subscriptions/utils';

export class InvestigationsCreateDataStore extends GraphqlBaseDataStore<Mutation, MutationCreateInvestigationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			create: action,
		});
	}

	async createWithQueryResults(args: Omit<MutationCreateInvestigationArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationCreateInvestigationArgs>({
			mutation: CreateInvestigationWithQueryResults,
			variables,
			update(cache, { data }) {
				const result = data?.createInvestigation;
				if (!result || result.__typename !== InvestigationTypename.Investigation) {
					return;
				}

				const investigationId = result.id;
				const alerts: UpdateListAlertsCacheAlertData[] = (result.alerts || []).map((item: InvestigationAlertSummary) => ({
					id: item.id,
					state: item.state,
				}));
				updateListAlertsCache(cache, investigationId, alerts);
			},
		});

		return response;
	}

	async create(args: Omit<MutationCreateInvestigationArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationCreateInvestigationArgs>({
			mutation: CreateInvestigation,
			variables,
			update(cache, { data }) {
				if (!data?.createInvestigation || data?.createInvestigation.__typename !== InvestigationTypename.Investigation) {
					return;
				}

				cache.modify({
					fields: {
						listInvestigations(existingInvestigationRefs: Reference | StoreObject, { readField }) {
							const node = cache.writeFragment({
								fragment: CoreInvestigationFragment,
								data: data.createInvestigation,
							});

							const newEdge = {
								node,
								__typename: InvestigationTypename.InvestigationEdge,
								cursor: '',
							};
							const edges = readField<InvestigationEdge[]>('edges', existingInvestigationRefs);

							const exists = edges?.some(
								(ref: InvestigationEdge) =>
									data.createInvestigation.__typename === InvestigationTypename.Investigation &&
									readField('id', ref.node) === data.createInvestigation.id,
							);

							if (exists) {
								return existingInvestigationRefs;
							}

							return {
								...(existingInvestigationRefs || {}),
								edges: [...(edges || []), newEdge],
							};
						},
					},
				});
			},
		});

		return response;
	}
}
