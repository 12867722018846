import { EnvironmentVariableType } from '@/generated/graphql';

import { safelyExecuteFunction } from '@/app/collectors/_common/utils';

import { handleFieldTypeBool } from './handle-field-type-bool';

export const convertStringAccordingToFieldType = (arg: string, fieldType?: EnvironmentVariableType): string | boolean => {
	if (fieldType === EnvironmentVariableType.Boolean) {
		return safelyExecuteFunction(handleFieldTypeBool, arg);
	}

	return arg;
};
