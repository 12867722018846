import { getQueryInputVariables } from '@/app/_common/utils';
import { action, computed, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { FiltersInput, Query } from '@/generated/graphql';
import { DocumentNode } from 'graphql';

export type ListIntegrations = Pick<Query, 'listIntegrations'>;

export class IntegrationsBaseDataStore extends GraphqlBaseDataStore<ListIntegrations> {
	constructor() {
		super();

		makeObservable(this, {
			integrations: computed,
			pageInfo: computed,
			readMore: action,
			read: action,
		});
	}

	get integrations() {
		return this.data;
	}

	get pageInfo() {
		return this.data?.listIntegrations?.pageInfo;
	}

	async readMore(query: DocumentNode, filtersInput: FiltersInput): Promise<void> {
		const { hasNextPage, endCursor } = this.pageInfo ?? {};

		if (hasNextPage) {
			const variables = {
				tenantId: this.authStore.currentTenantId,
				input: getQueryInputVariables({ after: endCursor }),
				filtersInput,
			};

			await this.fetchMore({
				query,
				variables,
			});
		}
	}

	read(query: DocumentNode, filtersInput: FiltersInput) {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			input: getQueryInputVariables(),
			filtersInput,
		};

		this.query({
			query,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
