import { IntegrationType } from '@/generated/graphql';

export const buildIntegrationDetailsPath = (type: IntegrationType, id: string) => {
	if (!type) {
		return '/';
	}

	if (!id) {
		return `/${type.toLowerCase()}/integrations`;
	}

	return `/${type.toLowerCase()}/integrations/${id}`;
};
