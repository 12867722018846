import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useMemo } from 'react';

import { CorrelatedAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/correlated-alert-evidence/correlated-alert-evidence';
import { GoogleWorkspaceAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/google-workspace-alert-evidence/google-workspace-alert-evidence';
import { MicrosoftDefenderAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/microsoft-defender-alert-evidence/microsoft-defender-alert-evidence';
import { CiscoMerakiAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/cisco-meraki-alert-evidence/cisco-meraki-alert-evidence';
import { FortinetFortigateAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/fortinet-fortigate-alert-evidence/fortinet-fortigate-alert-evidence';
import { PaloAltoNgfwAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/palo-alto-ngfw-alert-evidence/palo-alto-ngfw-alert-evidence';
import { OktaWorkforceAlertEvidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/okta-workforce-alert-evidence/okta-workforce-alert-evidence';
import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

import {
	CROWDSTRIKE_EVIDENCE_CATEGORY_CORRELATED_ALERT_VALUE,
	EvidenceApiDTO,
	GOOGLE_WORKSPACE_EVIDENCE_CATEGORY_ALERT_VALUE,
	MICROSOFT_DEFENDER_EVIDENCE_CATEGORY_ALERT_VALUE,
	CISCO_MERAKI_EVIDENCE_CATEGORY_ALERT_VALUE,
	PALO_ALTO_NGFW_EVIDENCE_CATEGORY_ALERT_VALUE,
	FORTINET_FORTIGATE_EVIDENCE_CATEGORY_ALERT_VALUE,
	OKTA_WORKFORCE_EVIDENCE_CATEGORY_ALERT_VALUE,
	SECIOSS_SECIOSSLINK_EVIDENCE_CATEGORY_ALERT_VALUE,
	TREND_MICRO_EVIDENCE_CATEGORY_ALERT_VALUE,
} from '@/app/_common/constants';
import { AlertVendorKey } from '@/app/_common/_components/details-view/types';

import styles from './vendor-alert-evidence-list.module.scss';
import { SeciossSeciosLinkAlertEvidence } from '../secioss-secios-link-alert-evidence/secioss-secios-link-alert-evidence';
import { TrendMicroAlertEvidence } from '../trend-micro-alert-evidence/trend-micro-alert-evidence';

interface VendorAlertEvidenceListProps {
	vendorKey: AlertVendorKey;
}

const vendorComponentMapping = {
	[AlertVendorKey.CROWDSTRIKE_FALCON]: {
		evidenceCategoryValue: CROWDSTRIKE_EVIDENCE_CATEGORY_CORRELATED_ALERT_VALUE,
		component: CorrelatedAlertEvidence,
	},
	[AlertVendorKey.GOOGLE_WORKSPACE]: {
		evidenceCategoryValue: GOOGLE_WORKSPACE_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: GoogleWorkspaceAlertEvidence,
	},
	[AlertVendorKey.MICROSOFT_DEFENDER]: {
		evidenceCategoryValue: MICROSOFT_DEFENDER_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: MicrosoftDefenderAlertEvidence,
	},
	[AlertVendorKey.CISCO_MERAKI]: {
		evidenceCategoryValue: CISCO_MERAKI_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: CiscoMerakiAlertEvidence,
	},
	[AlertVendorKey.PALO_ALTO_NGFW]: {
		evidenceCategoryValue: PALO_ALTO_NGFW_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: PaloAltoNgfwAlertEvidence,
	},
	[AlertVendorKey.FORTINET_FORTIGATE]: {
		evidenceCategoryValue: FORTINET_FORTIGATE_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: FortinetFortigateAlertEvidence,
	},
	[AlertVendorKey.OKTA_WORKFORCE]: {
		evidenceCategoryValue: OKTA_WORKFORCE_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: OktaWorkforceAlertEvidence,
	},
	[AlertVendorKey.SECIOSS_SECIOSSLINK]: {
		evidenceCategoryValue: SECIOSS_SECIOSSLINK_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: SeciossSeciosLinkAlertEvidence,
	},
	[AlertVendorKey.TREND_MICRO]: {
		evidenceCategoryValue: TREND_MICRO_EVIDENCE_CATEGORY_ALERT_VALUE,
		component: TrendMicroAlertEvidence,
	},
};

export const VendorAlertEvidenceList = observer(({ vendorKey }: VendorAlertEvidenceListProps) => {
	const alertDetailsNavigationStore = useInstance(AlertsDetailsNavigationViewStore);
	const { evidenceApiDetails } = alertDetailsNavigationStore;
	const vendorComponentData = vendorComponentMapping[vendorKey] ?? {};
	const { evidenceCategoryValue = '', component: Component = null } = vendorComponentData;

	const filteredDetails: EvidenceApiDTO[] = useMemo(() => {
		const evidenceList = Array.isArray(evidenceApiDetails) ? evidenceApiDetails : [];

		return evidenceList.filter((evidence) => {
			return evidence?.evidence_category === evidenceCategoryValue;
		});
	}, [evidenceApiDetails, evidenceCategoryValue]);

	if (!filteredDetails.length || !Component) {
		return null;
	}

	const isSingleItemView = filteredDetails.length === 1;

	return (
		<div className={styles.container}>
			{filteredDetails.map((evidence, index: number) => {
				const { evidence_id } = evidence;

				return <Component evidence={evidence} key={evidence_id} enumerate={index + 1} isSingleItemView={isSingleItemView} />;
			})}
		</div>
	);
});
