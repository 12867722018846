import { action, computed, makeObservable } from 'mobx';
import { DocumentNode } from 'graphql';

import { QueryListIntegrationsArgs, Query } from '@/generated/graphql';
import { getQueryInputVariables } from '@/app/_common/utils';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { getTelemetryIntegrationsFiltersInput } from '@/app/_common/utils/get-telemetry-integrations-filters-input/get-telemetry-integrations-filters-input';

type ListIntegrationsQuery = Pick<Query, 'listIntegrations'>;

export class BaseCollectorIntegrationsDataStore extends GraphqlBaseDataStore<ListIntegrationsQuery, QueryListIntegrationsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			integrations: computed,
			pageInfo: computed,
			readMore: action,
			read: action,
		});
	}

	get integrations() {
		return this.data?.listIntegrations?.edges ?? [];
	}

	get pageInfo() {
		return this.data?.listIntegrations?.pageInfo;
	}

	readMore(collectorId: string, query: DocumentNode) {
		const { hasNextPage, endCursor } = this.pageInfo ?? {};

		if (hasNextPage) {
			const filtersInput = getTelemetryIntegrationsFiltersInput(collectorId);

			const variables = {
				tenantId: this.authStore.currentTenantId,
				input: getQueryInputVariables({ after: endCursor }),
				filtersInput,
			};

			this.fetchMore({
				query,
				variables,
			});
		}
	}

	read(collectorId: string, query: DocumentNode) {
		const filtersInput = getTelemetryIntegrationsFiltersInput(collectorId);

		const variables: QueryListIntegrationsArgs = {
			tenantId: this.authStore.currentTenantId,
			input: getQueryInputVariables(),
			filtersInput,
		};

		this.query({
			query,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}
}
