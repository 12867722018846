export enum InvestigationPropertiesPaths {
	Id = 'node.id',
	Title = 'node.name',
	LastUpdated = 'node.last_updated',
	Priority = 'node.priority',
	Status = 'node.status',
	AssigneeDisplayName = 'node.assignee.displayName',
	FranchiseAssigneeDisplayName = 'node.franchiseAssignee.displayName',
}

export const InvestigationHeadersTranslationKeys = {
	[InvestigationPropertiesPaths.Title]: 'investigationsGrid.headers.title',
	[InvestigationPropertiesPaths.LastUpdated]: 'investigationsGrid.headers.lastUpdated',
	[InvestigationPropertiesPaths.Priority]: 'investigationsGrid.headers.priority',
	[InvestigationPropertiesPaths.Status]: 'investigationsGrid.headers.status',
	[InvestigationPropertiesPaths.AssigneeDisplayName]: 'investigationsGrid.headers.assigneeDisplayName',
	assignedLabelForFranchiseUsers: 'grid.headers.assignedToForFranchiseUsers',
};

export enum OpenInvestigationPropertiesPaths {
	Selected = 'selected',
	Id = 'node.id',
	Title = 'node.name',
	Type = 'node.type',
	Priority = 'node.priority',
	PriorityColor = 'priorityColor',
	AssigneeDisplayName = 'node.assignee.displayName',
	FranchiseAssigneeDisplayName = 'node.franchiseAssignee.displayName',
	Status = 'node.status',
	Timestamp = 'node.timestamp',
	LastUpdated = 'node.last_updated',
}

export const OpenInvestigationsHeadersTranslationKeys = {
	[OpenInvestigationPropertiesPaths.Title]: 'grid.headers.investigationName',
	[OpenInvestigationPropertiesPaths.Type]: 'grid.headers.type',
	[OpenInvestigationPropertiesPaths.Priority]: 'grid.headers.priority',
	[OpenInvestigationPropertiesPaths.AssigneeDisplayName]: 'grid.headers.assignedTo',
	[OpenInvestigationPropertiesPaths.FranchiseAssigneeDisplayName]: 'grid.headers.franchiseAssignedTo',
	assignedLabelForFranchiseUsers: 'grid.headers.assignedToForFranchiseUsers',
	[OpenInvestigationPropertiesPaths.Status]: 'grid.headers.status',
	[OpenInvestigationPropertiesPaths.Timestamp]: 'grid.headers.creationDate',
	[OpenInvestigationPropertiesPaths.LastUpdated]: 'grid.headers.lastUpdateDate',
};

export enum ClosedInvestigationPropertiesPaths {
	Id = 'node.id',
	Title = 'node.name',
	Type = 'node.type',
	Priority = 'node.priority',
	PriorityColor = 'priorityColor',
	AssigneeDisplayName = 'node.assignee.displayName',
	Timestamp = 'node.timestamp',
	ClosedTimestamp = 'node.closedDetails.timestamp',
	ClosedReason = 'node.closedDetails.reason',
	FranchiseAssigneeDisplayName = 'node.franchiseAssignee.displayName',
}

export const ClosedInvestigationsHeadersTranslationKeys = {
	[ClosedInvestigationPropertiesPaths.Title]: 'grid.headers.investigationName',
	[ClosedInvestigationPropertiesPaths.Type]: 'grid.headers.type',
	[ClosedInvestigationPropertiesPaths.Priority]: 'grid.headers.priority',
	[ClosedInvestigationPropertiesPaths.AssigneeDisplayName]: 'grid.headers.assignedTo',
	[ClosedInvestigationPropertiesPaths.Timestamp]: 'grid.headers.creationDate',
	[ClosedInvestigationPropertiesPaths.ClosedTimestamp]: 'grid.headers.closeDate',
	[ClosedInvestigationPropertiesPaths.ClosedReason]: 'grid.headers.closeReason',
	[ClosedInvestigationPropertiesPaths.FranchiseAssigneeDisplayName]: 'grid.headers.franchiseAssignedTo',
	assignedLabelForFranchiseUsers: 'grid.headers.assignedToForFranchiseUsers',
};

export enum EditInvestigationDialogKeyPrefix {
	Form = 'form',
}

export const InvestigationDetailsCountersColors = {
	alertsLabel: 'var(--investigationAlertsCounterLabelColor)',
	queryResultsLabel: 'var(--investigationQueryResultsCounterLabelColor)',
	totalIcon: 'var(--investigationTotalCounterIconColor)',
	alertIcon: 'var(--alertDataItemColor)',
	evidenceIcon: 'var(--evidenceDataItemColor)',
	eventIcon: 'var(--eventDataItemColor)',
};

export enum AssignToInvestigationTabs {
	createInvestigation,
	assignToInvestigation,
}
