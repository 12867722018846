import { action, makeObservable } from 'mobx';
import { Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { NoteTypename } from '@/app/_common/constants';
import { CoreNoteFragment, GraphqlBaseDataStore } from '@/app/_common/graphql';
import { CreateNote } from '@/app/_common/graphql/queries/notes.queries';
import { Mutation, MutationCreateNoteArgs, NoteEdge, Query } from '@/generated/graphql';

type ListNotes = Pick<Query, 'listNotes'>;

export class InvestigationNotesCreateDataStore extends GraphqlBaseDataStore<ListNotes> {
	constructor() {
		super();
		makeObservable(this, {
			create: action,
		});
	}

	async create(args: Omit<MutationCreateNoteArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationCreateNoteArgs>({
			mutation: CreateNote,
			variables,
			update(cache, { data }) {
				if (!data?.createNote || data.createNote.__typename !== NoteTypename.Note) {
					return;
				}

				cache.modify({
					fields: {
						listNotes(existingNoteRefs: Reference | StoreObject, { readField }) {
							const node = cache.writeFragment({
								fragment: CoreNoteFragment,
								data: data.createNote,
							});

							const newEdge = {
								node,
								__typename: NoteTypename.NoteEdge,
								cursor: '',
							};
							const edges = readField<NoteEdge[]>('edges', existingNoteRefs);

							const exists = edges?.some(
								(ref: NoteEdge) => data.createNote.__typename === NoteTypename.Note && readField('id', ref.node) === data.createNote.id,
							);

							if (exists) {
								return existingNoteRefs;
							}

							return {
								...(existingNoteRefs || {}),
								edges: [...(edges || []), newEdge],
							};
						},
					},
				});
			},
		});

		return response;
	}
}
