import { computed, makeObservable } from 'mobx';

import { CollectorType } from '@/generated/graphql';
import { ListCloudCollectorIntegrations } from '@/app/_common/graphql/queries';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { CollectorsDataStore, BaseCollectorIntegrationsDataStore } from '@/app/collectors/_common/stores';

export class CloudCollectorIntegrationsDataStore extends BaseCollectorIntegrationsDataStore {
	private dataStore = injectInterface(this, CollectorsDataStore);

	constructor() {
		super();

		makeObservable(this, {
			collector: computed,
		});
	}

	get collector() {
		return this.dataStore.collectors.find((edge) => edge.node.type === CollectorType.Cloud)?.node;
	}

	readMore() {
		if (!this.collector?.id) return;

		super.readMore(this.collector.id, ListCloudCollectorIntegrations);
	}

	read() {
		if (!this.collector?.id) return;

		super.read(this.collector.id, ListCloudCollectorIntegrations);
	}
}
