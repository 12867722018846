import { isCloudCollectorDisabled } from '@/app/create-integration/_common/utils';
import { CollectorType, IntegrationDefinition } from '@/generated/graphql';
import { DefinitionState } from '@/app/create-integration/_common/interfaces';

export const isIntegrationDefinitionDisabled = (integrationDefinition: IntegrationDefinition) => {
	const { state, collectorType, components } = integrationDefinition;

	if (state !== DefinitionState.Available) {
		return true;
	}

	if (collectorType === CollectorType.Cloud) {
		return isCloudCollectorDisabled(components);
	}

	return false;
};
