import i18n from '@/translations/i18n';
import { makeAutoObservable } from 'mobx';

import CollectorsViewStore from '@/app/collectors/_common/stores/collectors.view-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { CreateIntegrationDataStore } from './create-integration.data-store';
import { NotificationsStore, RouterStore } from '@/app/_common/stores';
import { IntegrationTypename } from '@/app/_common/constants';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import { Namespaces } from '@/translations/namespaces';
import { IntegrationDetailsStepViewStore } from './integration-details-step.view-store';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { ChooseIntegrationStepViewStore } from './choose-integration-step.view-store';
import { IntegrationFormData } from '@/app/create-integration/_common/interfaces';
import { buildIntegrationDetailsPath, prepareObjectToCreateIntegration } from '@/app/create-integration/_common/utils';
import { IntegrationType, CollectorType } from '@/generated/graphql';

export class CreateIntegrationViewStore {
	private notificationsStore = injectInterface(this, NotificationsStore);
	private routerStore = injectInterface(this, RouterStore);
	private createIntegrationDataStore = injectInterface(this, CreateIntegrationDataStore);
	private integrationDetailsStepViewStore = injectInterface(this, IntegrationDetailsStepViewStore);
	private chooseIntegrationStepViewStore = injectInterface(this, ChooseIntegrationStepViewStore);
	private collectorsViewStore = injectInterface(this, CollectorsViewStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get loading(): boolean {
		return this.createIntegrationDataStore.loading;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createIntegration = (handleSubmit: UseFormHandleSubmit<FieldValues>, event: any) => {
		handleSubmit(this.submitCreateIntegrationForm)(event);
	};

	submitCreateIntegrationForm = async (data: IntegrationFormData): Promise<void> => {
		const collectorId = this.getCollectorId();
		const newIntegration = prepareObjectToCreateIntegration({
			data,
			collectorId: collectorId,
			definitionId: this.chooseIntegrationStepViewStore.definitionId as string,
			required: this.integrationDetailsStepViewStore.getRequiredEnvironments(),
			environmentsFieldTypes: this.integrationDetailsStepViewStore.environmentsFieldTypes,
			type: this.chooseIntegrationStepViewStore.integrationType,
		});

		const response = await this.createIntegrationDataStore.create(newIntegration);

		const responseData = response?.data?.createIntegration;

		if (responseData?.__typename === IntegrationTypename.Integration) {
			const integrationDetailsPath = buildIntegrationDetailsPath(this.chooseIntegrationStepViewStore.integrationType, responseData.id);

			this.notificationsStore.openSuccess({
				title: i18n.t('createIntegration.success.title', { ns: Namespaces.Notifications, name: responseData?.name }),
				link: {
					text: i18n.t('createIntegration.success.content', { ns: Namespaces.Notifications, id: responseData?.id }),
					url: integrationDetailsPath,
				},
			});

			const integrationsPath = buildIntegrationDetailsPath(this.chooseIntegrationStepViewStore.integrationType, '');
			this.routerStore.push(integrationsPath);
		} else if (responseData?.__typename === IntegrationTypename.Error) {
			const error = getGraphQLError(i18n.t('createIntegration.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError(error);
		}
	};

	private getCollectorId(): string | undefined {
		const { integrationType, definition } = this.chooseIntegrationStepViewStore;
		const isResponseIntegration = integrationType === IntegrationType.Response;
		const isCloudCollector = definition?.collectorType === CollectorType.Cloud;
		if (isResponseIntegration || !isCloudCollector) {
			return undefined;
		}
		return this.collectorsViewStore.getCloudCollectorId();
	}
}
