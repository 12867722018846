import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@progress/kendo-react-common';
import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';
import { AlertEdge, AlertState, InvestigationAlertSummary } from '@/generated/graphql';
import { AlertPropertiesPaths } from '@/app/_common/constants';

import _get from 'lodash/get';

import styles from './alert-update-state-action.module.scss';

type ActionType = 'dismiss' | 'undismiss';

interface AlertUpdateStateActionProps {
	action: ActionType;
	handleSelectedAlertAction?: () => void;
	handleSingleAlertAction?: (alertId: string) => void;
	closeContextMenu?: () => void;
	state?: AlertState;
	count?: number;
	className?: string;
	dataItem: AlertEdge | InvestigationAlertSummary;
	isSelected?: boolean;
	hideAction?: boolean;
}

export const AlertUpdateStateAction: FC<AlertUpdateStateActionProps> = ({
	action,
	handleSelectedAlertAction = () => undefined,
	handleSingleAlertAction = () => undefined,
	closeContextMenu = () => undefined,
	state,
	count,
	className = '',
	dataItem,
	isSelected,
}) => {
	const { t } = useTranslation(Namespaces.AlertsDashboard);

	const id = _get(dataItem, AlertPropertiesPaths.Id);

	const displayCount = count ? `(${count})` : null;

	const expectedAction: ActionType = state && state !== AlertState.Dismissed ? 'dismiss' : 'undismiss';

	const handleTriggerSingleAlertAction = () => {
		if (id && handleSingleAlertAction) {
			handleSingleAlertAction(id);
		}

		closeContextMenu();
	};

	const handleTriggerAlertAction = () => {
		handleSelectedAlertAction();
		closeContextMenu();
	};

	if (!isSelected && action === expectedAction) {
		return (
			<div className={classNames(styles.container, className)} {...buttonize(handleTriggerSingleAlertAction)}>
				<p>{t(`singleAlert.${action}`)}</p>
			</div>
		);
	}

	if (!count || !isSelected) {
		return null;
	}

	return (
		<div className={classNames(styles.container, className)} {...buttonize(handleTriggerAlertAction)}>
			<p>
				{t(`${action}`)} {displayCount}
			</p>
		</div>
	);
};
