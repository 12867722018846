import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Namespaces } from './namespaces';

import enCommon from './en/common/common.json';
import jaCommon from './ja/common/common.json';

import enCollectors from './en/collectors/collectors.json';
import jaCollectors from './ja/collectors/collectors.json';

import enInvestigationDetails from './en/investigations/investigation-details.json';
import jaInvestigationDetails from './ja/investigations/investigation-details.json';

import enNotifications from './en/common/notifications/notifications.json';
import jaNotifications from './ja/common/notifications/notifications.json';

import enTimeRange from './en/common/time-range/time-range.json';
import jaTimeRange from './ja/common/time-range/time-range.json';

import enAdvancedQuery from './en/advanced-query/advanced-query.json';
import jaAdvancedQuery from './ja/advanced-query/advanced-query.json';

import enPageNotFound from './en/page-not-found/page-not-found.json';
import jaPageNotFound from './ja/page-not-found/page-not-found.json';

import enSidebar from './en/common/sidebar/sidebar.json';
import jaSidebar from './ja/common/sidebar/sidebar.json';

import enNavbarMenu from './en/common/navbar/navbar-menu.json';
import jaNavbarMenu from './ja/common/navbar/navbar-menu.json';

import enMainDashboard from './en/dashboards/main-dashboard.json';
import jaMainDashboard from './ja/dashboards/main-dashboard.json';

import enTelemetryDashboard from './en/dashboards/telemetry-dashboard.json';
import jaTelemetryDashboard from './ja/dashboards/telemetry-dashboard.json';

import enAlertsDashboard from './en/alerts/alerts-dashboard.json';
import jaAlertsDashboard from './ja/alerts/alerts-dashboard.json';

import enOpenInvestigations from './en/investigations/open-investigations.json';
import jaOpenInvestigations from './ja/investigations/open-investigations.json';

import enClosedInvestigations from './en/investigations/closed-investigations.json';
import jaClosedInvestigations from './ja/investigations/closed-investigations.json';

import enEditInvestigationDialog from './en/investigations/edit-investigation-dialog.json';
import jaEditInvestigationDialog from './ja/investigations/edit-investigation-dialog.json';

import enTenantManagement from './en/management/tenant-management.json';
import jaTenantManagement from './ja/management/tenant-management.json';

import enCreateInvestigationDialog from './en/investigations/create-investigation-dialog.json';
import jaCreateInvestigationDialog from './ja/investigations/create-investigation-dialog.json';

import enInvestigations from './en/investigations/investigations.json';
import jaInvestigations from './ja/investigations/investigations.json';

import enAssignAlertToInvestigation from './en/investigations/assign-alert-to-investigation.json';
import jaAssignAlertToInvestigation from './ja/investigations/assign-alert-to-investigation.json';

import enDetailsView from './en/details-view/details-view.json';
import jaDetailsView from './ja/details-view/details-view.json';

import enPageNotAccess from './en/page-not-access/page-not-access.json';
import jaPageNotAccess from './ja/page-not-access/page-not-access.json';

import enNotes from './en/notes/notes.json';
import jaNotes from './ja/notes/notes.json';

import enIntegrations from './en/integrations/integrations.json';
import jaIntegrations from './ja/integrations/integrations.json';

import enCreateIntegration from './en/integrations/create-integration.json';
import jaCreateIntegration from './ja/integrations/create-integration.json';

import enUpdateIntegration from './en/integrations/update-integration.json';
import jaUpdateIntegration from './ja/integrations/update-integration.json';

import enWizard from './en/common/wizard/wizard.json';
import jaWizard from './ja/common/wizard/wizard.json';

import enDeleteCard from './en/delete-card/delete-card.json';
import jaDeleteCard from './ja/delete-card/delete-card.json';

import enBreacrumbs from './en/common/breadcrumbs/breadcrumbs.json';
import jaBreacrumbs from './ja/common/breadcrumbs/breadcrumbs.json';

import enTelemetryMainPage from './en/telemetry/main-page.json';
import jaTelemetryMainPage from './ja/telemetry/main-page.json';

import enResponseMainPage from './en/response/main-page.json';
import jaResponseMainPage from './ja/response/main-page.json';

import enResponseIntegrationsPage from './en/response/integrations-page.json';
import jaResponseIntegrationsPage from './ja/response/integrations-page.json';

import enAlertsActions from './en/common/alerts-actions/alerts-actions.json';
import jaAlertsActions from './ja/common/alerts-actions/alerts-actions.json';

import enAlertsActionDialog from './en/common/alerts-action-dialog/alerts-action-dialog.json';
import jaAlertsActionDialog from './ja/common/alerts-action-dialog/alerts-action-dialog.json';

import enUsersManagement from './en/management/users.json';
import jaUsersManagement from './ja/management/users.json';

import enActionHistory from './en/management/action-history.json';
import jaActionHistory from './ja/management/action-history.json';

import enEndpoints from './en/response/endpoints-page.json';
import jaEndpoints from './ja/response/endpoints-page.json';

import enCreateResponseIntegration from './en/create-integration/create-response-integration.json';
import jaCreateResponseIntegration from './ja/create-integration/create-response-integration.json';

import enIntegrationDetails from './en/integration-details/integration-details.json';
import jaIntegrationDetails from './ja/integration-details/integration-details.json';

import enErrorDetails from './en/common/error-details/error-details.json';
import jaErrorDetails from './ja/common/error-details/error-details.json';

import enIntegrationDelete from './en/integration-delete/integration-delete.json';
import jaIntegrationDelete from './ja/integration-delete/integration-delete.json';

import enQueryResults from './en/query-results/query-results.json';
import jaQueryResults from './ja/query-results/query-results.json';

import enFranchiseClients from './en/franchise/clients.json';
import jaFranchiseClients from './ja/franchise/clients.json';

import enCreateFranchiseClient from './en/franchise/create-client.json';
import jaCreateFranchiseClient from './ja/franchise/create-client.json';

import enTools from './en/tools/tools.json';
import jaTools from './ja/tools/tools.json';

import enCompromiseMonitor from './en/tools/compromise-monitor.json';
import jaCompromiseMonitor from './ja/tools/compromise-monitor.json';

import enListHeader from './en/common/list-header/list-header.json';
import jaListHeader from './ja/common/list-header/list-header.json';

const resources = {
	en: {
		common: enCommon,
		clients: enFranchiseClients,
		createFranchiseClient: enCreateFranchiseClient,
		investigationDetails: enInvestigationDetails,
		notifications: enNotifications,
		advancedQuery: enAdvancedQuery,
		sidebar: enSidebar,
		pageNotFound: enPageNotFound,
		pageNotAccess: enPageNotAccess,
		navbarMenu: enNavbarMenu,
		mainDashboard: enMainDashboard,
		telemetryDashboard: enTelemetryDashboard,
		alertsDashboard: enAlertsDashboard,
		investigations: enInvestigations,
		openInvestigations: enOpenInvestigations,
		closedInvestigations: enClosedInvestigations,
		editInvestigationDialog: enEditInvestigationDialog,
		tenantManagement: enTenantManagement,
		createInvestigationDialog: enCreateInvestigationDialog,
		assignAlertToInvestigation: enAssignAlertToInvestigation,
		detailsView: enDetailsView,
		collectors: enCollectors,
		timeRange: enTimeRange,
		notes: enNotes,
		integrations: enIntegrations,
		createIntegration: enCreateIntegration,
		updateIntegration: enUpdateIntegration,
		wizard: enWizard,
		deleteCard: enDeleteCard,
		breadcrumbs: enBreacrumbs,
		telemetryMainPage: enTelemetryMainPage,
		responseMainPage: enResponseMainPage,
		responseIntegrationsPage: enResponseIntegrationsPage,
		alertsActions: enAlertsActions,
		alertsActionDialog: enAlertsActionDialog,
		users: enUsersManagement,
		actionHistory: enActionHistory,
		endpoints: enEndpoints,
		createResponseIntegration: enCreateResponseIntegration,
		integrationDetails: enIntegrationDetails,
		errorDetails: enErrorDetails,
		integrationDelete: enIntegrationDelete,
		queryResults: enQueryResults,
		tools: enTools,
		compromiseMonitor: enCompromiseMonitor,
		listHeader: enListHeader,
	},
	ja: {
		common: jaCommon,
		clients: jaFranchiseClients,
		createFranchiseClient: jaCreateFranchiseClient,
		investigationDetails: jaInvestigationDetails,
		notifications: jaNotifications,
		advancedQuery: jaAdvancedQuery,
		sidebar: jaSidebar,
		pageNotFound: jaPageNotFound,
		pageNotAccess: jaPageNotAccess,
		navbarMenu: jaNavbarMenu,
		mainDashboard: jaMainDashboard,
		telemetryDashboard: jaTelemetryDashboard,
		alertsDashboard: jaAlertsDashboard,
		investigations: jaInvestigations,
		openInvestigations: jaOpenInvestigations,
		closedInvestigations: jaClosedInvestigations,
		editInvestigationDialog: jaEditInvestigationDialog,
		tenantManagement: jaTenantManagement,
		createInvestigationDialog: jaCreateInvestigationDialog,
		assignAlertToInvestigation: jaAssignAlertToInvestigation,
		detailsView: jaDetailsView,
		collectors: jaCollectors,
		timeRange: jaTimeRange,
		notes: jaNotes,
		integrations: jaIntegrations,
		createIntegration: jaCreateIntegration,
		updateIntegration: jaUpdateIntegration,
		wizard: jaWizard,
		deleteCard: jaDeleteCard,
		breadcrumbs: jaBreacrumbs,
		telemetryMainPage: jaTelemetryMainPage,
		responseMainPage: jaResponseMainPage,
		responseIntegrationsPage: jaResponseIntegrationsPage,
		alertsActions: jaAlertsActions,
		alertsActionDialog: jaAlertsActionDialog,
		users: jaUsersManagement,
		actionHistory: jaActionHistory,
		endpoints: jaEndpoints,
		createResponseIntegration: jaCreateResponseIntegration,
		integrationDetails: jaIntegrationDetails,
		errorDetails: jaErrorDetails,
		integrationDelete: jaIntegrationDelete,
		queryResults: jaQueryResults,
		tools: jaTools,
		compromiseMonitor: jaCompromiseMonitor,
		listHeader: jaListHeader,
	},
} satisfies Record<'en' | 'ja', Record<Namespaces, object>>;

i18n.use(initReactI18next).init({
	debug: false,
	resources,
	lng: 'en', // can be used to set default language to Japanese when testing locally
	interpolation: {
		escapeValue: false,
	},
	defaultNS: Namespaces.Common,
	ns: [Namespaces.Common],
	returnNull: false,
});

export default i18n;
