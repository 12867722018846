import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { makeAutoObservable } from 'mobx';
import { makePersistable, PersistenceStorageOptions, stopPersisting } from 'mobx-persist-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChildStateRefType = { [key: string]: any };

export class SwitchTenantStore {
	private authStore = injectInterface(this, AuthStore);
	private childStateRef: ChildStateRefType | null = null;
	private storageOptions: PersistenceStorageOptions<ChildStateRefType, keyof ChildStateRefType> = {
		name: `${this.authStore.currentTenantId}_storage_placeholder`,
		properties: [],
	};
	private readonly source: string = '';

	constructor(source: string) {
		this.source = source;
		makeAutoObservable(this);
	}

	init = (childStateRef: ChildStateRefType, storageOptions: PersistenceStorageOptions<ChildStateRefType, keyof ChildStateRefType>) => {
		this.childStateRef = childStateRef;
		this.storageOptions = storageOptions;
		const { currentTenantId } = this.authStore;
		if (this.childStateRef !== null) {
			stopPersisting(this.childStateRef);
			return makePersistable(this.childStateRef, { ...this.storageOptions, name: `${currentTenantId}/${this.storageOptions.name}` });
		}
	};
}
