import { CollectorStatus } from '@/generated/graphql';

const SUCCESS_STATUSES = [CollectorStatus.Healthy];

const WARNING_STATUSES = [CollectorStatus.NotHealthy];

const PENDING_STATUSES = [CollectorStatus.Provisioning];

const ERROR_STATUSES = [CollectorStatus.AdministrativelyDown, CollectorStatus.Offline, CollectorStatus.NotAvailable];

export const getCollectorStatusColor = (value?: CollectorStatus): string => {
	if (!value) {
		return 'var(--collectorStatusEmptyColor)';
	}

	if (SUCCESS_STATUSES.includes(value)) {
		return 'var(--collectorStatusSuccessColor)';
	}

	if (PENDING_STATUSES.includes(value)) {
		return 'var(--collectorStatusPendingColor)';
	}

	if (WARNING_STATUSES.includes(value)) {
		return 'var(--collectorStatusWarningColor)';
	}

	if (ERROR_STATUSES.includes(value)) {
		return 'var(--collectorStatusErrorColor)';
	}

	return 'var(--collectorStatusEmptyColor)';
};
