import { convertBooleanToString } from '@/app/collectors/_common/utils';
import { BoolStrings, IntegrationFormFieldDataType } from '@/app/collectors/_common/types';

export const handleBoolType = (value: IntegrationFormFieldDataType, isRequired: boolean) => {
	if (typeof value === 'boolean') {
		return convertBooleanToString(value);
	}

	if (isRequired && value === undefined) {
		return BoolStrings.False;
	}

	return value;
};
