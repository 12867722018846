import { Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import _isNumber from 'lodash/isNumber';

import { AlertAssignmentCount } from '@/generated/graphql';
import { AlertTypename } from '@/app/_common/constants';
import { SetAlertsStateUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { setAlertStateListAlertsUpdate } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheUndismissAlertsDashboard: SetAlertsStateUpdateCacheFn = (cache, { data }) => {
	if (!data?.setAlertsState || data?.setAlertsState.__typename !== AlertTypename.Alerts) {
		return;
	}

	const alerts = data?.setAlertsState?.alerts || [];

	if (!alerts.length) {
		return;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(cache as unknown as any).modify({
		fields: {
			getAlertCount(existingAlertCount: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) {
				const assignment = readField('assignment', existingAlertCount) as AlertAssignmentCount;

				return {
					...(existingAlertCount || {}),
					assignment: {
						...(assignment || {}),
						...(_isNumber(assignment?.unassigned) ? { unassigned: assignment.unassigned + alerts.length } : {}),
						...(_isNumber(assignment?.dismissed) ? { dismissed: assignment.dismissed - alerts.length } : {}),
					},
				};
			},
			listAlerts: setAlertStateListAlertsUpdate(cache, alerts),
		},
	});
};
