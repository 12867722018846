import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useInstance } from 'react-ioc';

import { IntegrationDetailsStepViewStore } from '@/app/create-integration/_common/stores';

import { IntegrationDetailsForm } from '@/app/_common/_components';
import { IntegrationDetailsFormDisabledProp } from '@/app/_common/types';

interface IntegrationDetailsFormWrapperProps {
	formDisabledState?: IntegrationDetailsFormDisabledProp;
	initialValues?: Record<string, string>;
	register: UseFormRegister<FieldValues>;
}

export enum StaticFields {
	Name = 'name',
	Description = 'description',
}

export const IntegrationDetailsFormWrapper: FC<IntegrationDetailsFormWrapperProps> = observer(({ formDisabledState, initialValues, register }) => {
	const formStore = useInstance(IntegrationDetailsStepViewStore);

	return (
		<IntegrationDetailsForm
			register={register}
			environments={formStore.environments}
			errors={formStore.errors}
			formDisabledState={formDisabledState || formStore.formDisabledState}
			initialValues={initialValues}
		/>
	);
});
