import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { Reference } from '@apollo/client';
import { StoreObject } from '@apollo/client/utilities';

import { Investigation, InvestigationEdge } from '@/generated/graphql';
import { InvestigationTypename } from '@/app/_common/constants';

export const assignAlertListInvestigationUpdate =
	(node: Investigation) =>
	(existingInvestigationRefs: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) => {
		const edges = readField<InvestigationEdge[]>('edges', existingInvestigationRefs);
		const exists = edges?.some(
			(ref: InvestigationEdge) => node?.__typename === InvestigationTypename.Investigation && readField('id', ref.node) === node?.id,
		);

		if (exists) {
			return existingInvestigationRefs;
		}

		return {
			...existingInvestigationRefs,
			edges: [
				...(edges || []),
				{
					node,
					__typename: InvestigationTypename.InvestigationEdge,
				},
			],
		};
	};
