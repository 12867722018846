export enum ActionHistoryActions {
	CREATE = 'CREATE',
	READ = 'READ',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	ISOLATE = 'ISOLATE',
	DEISOLATE = 'DEISOLATE',
}

export enum ActionHistoryCategory {
	ALERT = 'ALERT',
	COLLECTOR = 'COLLECTOR',
	EMAIL = 'EMAIL',
	INTEGRATION = 'INTEGRATION',
	INVESTIGATION = 'INVESTIGATION',
	NOTE = 'NOTE',
	QUERY_RESULT = 'QUERY_RESULT',
	TENANT = 'TENANT',
	USER = 'USER',
	ENDPOINT = 'ENDPOINT',
	FRANCHISE = 'FRANCHISE',
}
