import { ReactElement, forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import { Card, CardHeader, CardTitle, CardBody } from '@progress/kendo-react-layout';

import { LoadingSpinner } from '@/app/_common/_components/loading-spinner/loading-spinner';

import styles from './widget-container.module.scss';

interface WidgetContainerProps {
	header?: ReactElement;
	title?: string;
	className?: string;
	childrenContainerClassName?: string;
	loading?: boolean;
	fullHeight?: boolean;
	overlay?: boolean;
	children?: ReactNode;
}

export const WidgetContainer = forwardRef<HTMLDivElement, WidgetContainerProps>(
	({ header, title, children, loading, className, childrenContainerClassName, fullHeight, overlay }, ref) => {
		return (
			<Card className={classNames(styles.card, className, { [styles.fullHeight]: fullHeight })}>
				{!!header && header}
				{!header && title && (
					<CardHeader>
						<CardTitle>{title}</CardTitle>
					</CardHeader>
				)}
				{loading && <LoadingSpinner className={styles.loadingSpinner} />}
				{!loading && (
					<CardBody className={styles.childrenWrapper}>
						<div className={classNames(styles.childrenContainer, childrenContainerClassName)} ref={ref}>
							{children}
						</div>
					</CardBody>
				)}
				{overlay && <div className={styles.overlay} />}
			</Card>
		);
	},
);

WidgetContainer.displayName = 'WidgetContainer';
