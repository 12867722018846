import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';

import styles from './guide-documentation.module.scss';

interface GuideDocumentationProps {
	text: string | JSX.Element;
	link: string;
}

export const GuideDocumentation: FC<GuideDocumentationProps> = ({ text, link }) => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);

	return (
		<div className={styles.guideDocumentation}>
			<h3 className={styles.title}>{t('steps.telemetryCollection.guide.guideDocumentation')}</h3>
			<p className={styles.guideContent}>{text}</p>
			<ExternalLink to={link} text={t('steps.telemetryCollection.guide.link')} />
		</div>
	);
};
