import { makeAutoObservable } from 'mobx';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';
import { Descendant } from 'slate';

import { InvestigationTypename, QueryResultTypename } from '@/app/_common/constants';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Investigation, InvestigationQueryResultSummary, UpdateQueryResultInput } from '@/generated/graphql';
import { AuthStore, NotificationsStore } from '@/app/_common/stores';
import { InvestigationDetailsDataStore } from './investigation-details.data-store';
import { QueryResultsViewStore } from './query-results.view-store';
import { QueryResultsUpdateDataStore } from './query-results-update.data-store';
import { QueryResultsUnassignDataStore } from './query-results-unassign.data-store';
import { getFormattedDate } from '@/app/_common/utils';
import { countCharacters } from '@/app/_common/_components';

import type { InvestigationDetailsQueryResultsContributor, Notification } from '@/app/_common/types';

interface State {
	unassignDialog: {
		open: boolean;
		errors: Notification[];
	};
	isReasonWindowOpen: boolean;
	editedReasonId?: string;
}

const INITIAL_STATE: State = {
	unassignDialog: {
		open: false,
		errors: [],
	},
	isReasonWindowOpen: false,
	editedReasonId: undefined,
};

export class QueryResultViewStore {
	private investigationDetailsDataStore = injectInterface(this, InvestigationDetailsDataStore);
	private queryResultsViewStore = injectInterface(this, QueryResultsViewStore);
	private queryResultsUpdateDataStore = injectInterface(this, QueryResultsUpdateDataStore);
	private queryResultsUnassignDataStore = injectInterface(this, QueryResultsUnassignDataStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private authStore = injectInterface(this, AuthStore);

	private state: State = INITIAL_STATE;

	constructor(private resultId: string) {
		makeAutoObservable(this);
	}

	get node() {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.queryResultsViewStore.queryResults.find(({ node }) => node.id === this.resultId)!.node;
	}

	get id() {
		return this.resultId;
	}

	get isReasonWindowOpen() {
		return this.state.isReasonWindowOpen;
	}

	get editedReasonId() {
		return this.state.editedReasonId;
	}

	get loading() {
		return this.queryResultsUpdateDataStore.loading;
	}

	get investigationQueryResultSummary(): InvestigationQueryResultSummary | undefined {
		return (this.investigationDetailsDataStore.investigation as Investigation).queryResults?.find((queryResult) => queryResult.id === this.id);
	}

	get investigationQueryResultEvidenceCount() {
		return this.investigationQueryResultSummary?.evidenceCount ?? 0;
	}

	get investigationQueryResultEventCount() {
		return this.investigationQueryResultSummary?.eventCount ?? 0;
	}

	get investigationQueryResultAlertCount() {
		return this.investigationQueryResultSummary?.alertCount ?? 0;
	}

	get investigationQueryResultCreatedTimestamp() {
		return getFormattedDate(this.node.timestamp);
	}

	get reason() {
		return this.node.reason ?? '';
	}

	get isUnassignConfirmDialogOpen() {
		return this.state.unassignDialog.open;
	}

	get unassignConfirmDialogErrors() {
		return this.state.unassignDialog.errors;
	}

	get unassignConfirmDialogInProgress() {
		return this.queryResultsUnassignDataStore.loading;
	}

	get investigationQueryResultContributors(): InvestigationDetailsQueryResultsContributor[] {
		const { updatedBy, timestamp, requesterUser } = this.node;

		const allContributions: InvestigationDetailsQueryResultsContributor[] = [
			{
				label: requesterUser?.upn ?? this.authStore.franchiseName,
				lastActivity: new Date(timestamp),
			},
			...updatedBy.map((node) => ({
				label: node.requesterUser?.upn ?? this.authStore.franchiseName,
				lastActivity: new Date(node.timestamp),
			})),
		];

		const grouped = _groupBy(allContributions, 'label');
		const uniqueContributors = Object.values(grouped)
			.map((group) => _maxBy(group, (item) => item.lastActivity.getTime()))
			.filter((item: InvestigationDetailsQueryResultsContributor | undefined): item is InvestigationDetailsQueryResultsContributor => !!item);

		return uniqueContributors.sort((a, b) => a.lastActivity.getTime() - b.lastActivity.getTime());
	}

	openReasonWindow = () => {
		this.state.isReasonWindowOpen = true;
	};

	closeReasonWindow = () => {
		this.state.isReasonWindowOpen = false;
	};

	setEditedReasonId = (id: string) => {
		this.state.editedReasonId = id;
	};

	clearEditedReasonId = () => {
		this.state.editedReasonId = undefined;
	};

	updateMitreAttack = (newMitreValues: string[]) => {
		const updateQueryResultInput: UpdateQueryResultInput = {
			id: this.node.id,
			investigationId: this.node.investigationId,
			mitre: newMitreValues,
		};
		this.submitUpdateQueryResult(updateQueryResultInput, i18n.t('updateQueryResult.mitreAttack.error.title', { ns: Namespaces.Notifications }));
	};

	updateReason = (newReason: string) => {
		const updateQueryResultInput: UpdateQueryResultInput = {
			id: this.node.id,
			investigationId: this.node.investigationId,
			reason: countCharacters(JSON.parse(newReason) as Descendant[]) === 0 ? null : newReason,
		};
		this.submitUpdateQueryResult(updateQueryResultInput, i18n.t('updateQueryResult.reason.error.title', { ns: Namespaces.Notifications }));
		this.clearEditedReasonId();
	};

	submitUpdateQueryResult = async (updateQueryResultInput: UpdateQueryResultInput, errorMessage: string) => {
		const response = await this.queryResultsUpdateDataStore.update({
			updateQueryResultInput,
		});

		const responseData = response?.data?.updateQueryResult;

		if (responseData?.__typename === QueryResultTypename.Error) {
			const error = getGraphQLError(errorMessage, responseData);
			this.notificationsStore.openError(error);
			return false;
		}
	};

	submitUnassignQueryResult = async () => {
		this.queryResultsViewStore.setRemovedId(this.id);
		const response = await this.queryResultsUnassignDataStore.unassign({
			investigationId: (this.investigationDetailsDataStore.investigation as Investigation).id,
			queryResultId: this.id,
		});

		const responseData = response?.data?.unassignQueryResultFromInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.notificationsStore.openSuccess({
				title: i18n.t('updateQueryResult.remove.success.title', { ns: Namespaces.Notifications }),
				content: i18n.t('updateQueryResult.remove.success.content', { ns: Namespaces.Notifications }),
			});
		}
		if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('updateQueryResult.unassign.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError({
				title: i18n.t('updateQueryResult.remove.error.title', { ns: Namespaces.Notifications }),
				content: i18n.t('updateQueryResult.remove.error.content', { ns: Namespaces.Notifications, details: error.content }),
			});
		}
	};

	openUnassignConfirmDialogModal = () => {
		this.state.unassignDialog.open = true;
	};

	closeUnassignConfirmDialogModal = () => {
		this.state.unassignDialog.open = false;
	};
}
