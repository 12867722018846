import { UserEdge, FranchiseUserEdge } from '@/generated/graphql';
import { DropdownOption } from '@/app/_common/_components/forms/_common';

export const getAssigneesOptions = (users: UserEdge[] | FranchiseUserEdge[] = []) => {
	const unassignedAssignee: DropdownOption = { value: null, label: 'Unassigned' };

	let assignees: DropdownOption[] =
		users?.map(({ node }) => ({
			value: node.id as string | null,
			label: node.displayName ?? '',
		})) || [];

	assignees = [unassignedAssignee, ...assignees];

	return assignees;
};
