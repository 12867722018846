import { action, computed, makeObservable, reaction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { getNullishFilterOption, sortInvestigations } from '@/app/_common/utils';
import { InvestigationEdge } from '@/generated/graphql';
import { InvestigationPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { FilterOption } from '@/app/_common/types';

import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';
import { InvestigationsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/investigations.data-store';
import { AuthStore } from '@/app/_common/stores';
import i18n from '@/translations/i18n';
import { SwitchTenantStore } from '@/app/_common/stores/switch-tenant.store';

const INITIAL_COLUMNS = {
	[InvestigationPropertiesPaths.Title]: true,
	[InvestigationPropertiesPaths.LastUpdated]: true,
	[InvestigationPropertiesPaths.Priority]: true,
	[InvestigationPropertiesPaths.Status]: true,
	[InvestigationPropertiesPaths.AssigneeDisplayName]: true,
	[InvestigationPropertiesPaths.FranchiseAssigneeDisplayName]: true,
};

const INITIAL_SORT = [{ field: InvestigationPropertiesPaths.LastUpdated, dir: SortDirection.Desc }];

const EMPTY_FIELD_VALUE = 'empty';
export class InvestigationsListViewStore extends DataGridViewStore<InvestigationEdge> {
	private investigationsDataStore = injectInterface(this, InvestigationsDataStore);
	private authStore = injectInterface(this, AuthStore);
	private switchTenantStore = new SwitchTenantStore('InvestigationsListViewStore');

	constructor() {
		super(InvestigationPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortInvestigations);

		makeObservable(this, {
			investigations: computed,
			loading: computed,
			error: computed,
			refresh: action,
			dispose: action,
		});

		this.initializeSessionStorage();

		makePersistable(this.gridState, {
			name: 'ls/dashboards/investigations/widget',
			properties: ['columns'],
			storage: window.localStorage,
		});

		this.setSort([{ field: InvestigationPropertiesPaths.LastUpdated, dir: SortDirection.Desc }]);
	}

	get investigations() {
		return [...(this.investigationsDataStore.investigations?.listInvestigations.edges || [])];
	}

	get loading(): boolean {
		return this.investigationsDataStore.loading;
	}

	get error() {
		return this.investigationsDataStore.error;
	}

	getInvestigationNameById = (id: string) => {
		const investigation = this.investigations.find((i) => i.node.id === id);
		return investigation?.node.name || '';
	};

	getFilterOptions = (field: string, isNull?: boolean) => {
		const counters = this.getCountedValues(field);
		const options: FilterOption[] = [
			...this.getUniqValues(field).map((value) => ({
				value: value as string,
				label: value === 'Sleep' ? 'Snooze' : (value as string),
				counter: counters[value as string],
			})),
		];

		if (isNull) {
			options.unshift(getNullishFilterOption(i18n.t('unassigned'), EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}

		return options;
	};

	refresh = () => {
		this.investigationsDataStore.read();
		this.resetAllFilters();
		this.initializeSessionStorage();
	};

	investigationsDisposer = reaction(
		() => this.investigationsDataStore.investigations?.listInvestigations.edges,
		(investigations) => {
			this.sourceData = investigations;
		},
	);

	tenantChangeDisposer = reaction(() => this.authStore.currentTenantId, this.refresh);

	dispose = () => {
		this.investigationsDisposer();
		this.tenantChangeDisposer();
		stopPersisting(this.gridState);
	};

	private initializeSessionStorage = (): void => {
		this.switchTenantStore.init(this.gridState, {
			name: 'ss/dashboards/investigations/widget',
			properties: ['sort', 'filter'],
			storage: window.sessionStorage,
		});
	};
}

export type InvestigationRow = InvestigationsListViewStore['investigations'][0];
