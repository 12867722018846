import { useTranslation } from 'react-i18next';
import { useId } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Namespaces } from '@/translations/namespaces';
import { InfoTooltip, Switch } from '@/app/_common/_components';
import { IntegrationEditPageViewStore } from '@/app/integration-edit/stores';

import styles from './dynamic-column-header.module.scss';

const TOOLTIP_OFFSET_TOP = 8;

export const UpdateFormDynamicColumnHeader = observer(() => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);
	const tooltipId = useId();
	const store = useInstance(IntegrationEditPageViewStore);

	const ColumnTitle = <div className={styles.columnTitle}>{t('columnHeader.configuration')}</div>;

	if (!store) {
		return ColumnTitle;
	}

	const { isConfigurationSwitchChecked, toggleIsConfigurationSwitchChecked } = store;

	return (
		<div className={styles.container}>
			<div className={styles.column}>
				{ColumnTitle}
				<InfoTooltip
					description={t('tooltips.testUpdatedConfiguration')}
					id={tooltipId}
					place="top"
					offsetTop={TOOLTIP_OFFSET_TOP}
					tooltipClassName={styles.tooltip}
				/>
			</div>
			<div className={styles.column}>
				<Switch checked={isConfigurationSwitchChecked} onChange={toggleIsConfigurationSwitchChecked} className={styles.switch} />
				<div className={styles.edit}>{t('columnHeader.edit')}</div>
			</div>
		</div>
	);
});
