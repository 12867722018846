import { gql, Reference } from '@apollo/client';
import { action, makeObservable } from 'mobx';
import _pick from 'lodash/pick';
import _isNumber from 'lodash/isNumber';
import { StoreObject } from '@apollo/client/utilities';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { AssignAlertsToInvestigation } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { InvestigationTypename } from '@/app/_common/constants/graphql.constants';
import { AlertAssignmentCount, AlertEdge, AlertState, Mutation, MutationSetAlertsOnInvestigationArgs } from '@/generated/graphql';
import { AlertTypename, AlertStateOptions } from '@/app/_common/constants';
import { InvestigationSelectDataStore } from '@/app/_common/_components/forms/assign-to-investigation-form/_common/stores';
import { AlertsDataStore } from './alerts.data-store';

export class AlertsAssignToInvestigationDataStore extends GraphqlBaseDataStore<Mutation, MutationSetAlertsOnInvestigationArgs> {
	private alertsInvestigationDataStore = injectInterface(this, InvestigationSelectDataStore);
	private alertsDataStore = injectInterface(this, AlertsDataStore);

	constructor() {
		super();
		makeObservable(this, {
			assign: action,
		});
	}

	async assign(data: Omit<MutationSetAlertsOnInvestigationArgs, 'tenantId' | 'alertIds'>, newAlertIds: string[]) {
		const investigation = this.alertsInvestigationDataStore.investigations.find(({ node }) => node.id === data.investigationId);
		const investigationAlertIds = investigation?.node?.alerts?.map(({ id }) => id) || [];

		const requesterUser = _pick(this.authStore.user, ['upn', 'id']);
		const alerts: AlertEdge[] = this.alertsDataStore.alerts.filter((alert) => newAlertIds.includes(alert.node.id));

		const dismissedAlertsCount = alerts.filter((alert) => alert.node.state?.alertState === AlertState.Dismissed).length;
		const unassignedAlertsCount = alerts.filter((alert) => !alert.node.state).length;

		const args = {
			...data,
			alertIds: [...newAlertIds, ...investigationAlertIds],
		};

		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationSetAlertsOnInvestigationArgs>({
			mutation: AssignAlertsToInvestigation,
			variables,
			update(cache, { data }) {
				if (!data?.setAlertsOnInvestigation || data?.setAlertsOnInvestigation.__typename !== InvestigationTypename.Investigation) {
					return;
				}

				const investigationId = data?.setAlertsOnInvestigation?.id;
				const investigationUpdatedTimestamp = data?.setAlertsOnInvestigation?.last_updated;
				const alerts = data?.setAlertsOnInvestigation?.alerts || [];
				const alertsIds = alerts?.map(({ id }) => id) || [];

				if (!investigationId || alerts.length === 0) {
					return;
				}

				cache.modify({
					fields: {
						getAlertCount(existingAlertCount: Reference | StoreObject, { readField }: { readField: ReadFieldFunction }) {
							const assignment = readField<AlertAssignmentCount>('assignment', existingAlertCount);
							const assigned = assignment?.assigned;
							const hasAssigned = _isNumber(assigned);
							const unassigned = assignment?.unassigned;
							const hasUnassigned = _isNumber(unassigned);
							const dismissed = assignment?.dismissed;
							const hasDismissed = _isNumber(dismissed);

							return {
								...(existingAlertCount || {}),
								assignment: {
									...(assignment || {}),
									...(hasAssigned ? { assigned: assigned + newAlertIds.length } : {}),
									...(hasUnassigned ? { unassigned: unassigned - unassignedAlertsCount } : {}),
									...(hasDismissed ? { dismissed: dismissed - dismissedAlertsCount } : {}),
								},
							};
						},
						listAlerts(existingAlertRefs: Reference | StoreObject, { readField }) {
							return {
								...(existingAlertRefs || {}),
								edges: readField<AlertEdge[]>('edges', existingAlertRefs)?.map((edgeRef) => {
									const alertId = readField('id', edgeRef.node);

									if (typeof alertId === 'string' && alertsIds.includes(alertId)) {
										const alert = {
											__typename: AlertTypename.Alert,
											id: alertId,
										};

										const updatedAlertRef = cache.writeFragment({
											id: cache.identify(alert),
											data: {
												state: {
													alertState: AlertStateOptions.Assigned,
													timestamp: investigationUpdatedTimestamp,
													requesterUser,
												},
												investigationSummary: {
													id: investigationId,
												},
											},
											fragment: gql`
												# eslint-disable-next-line @graphql-eslint/no-unused-fragments
												fragment UpdatedAlert on Alert {
													state {
														alertState
														timestamp
														requesterUser {
															id
															upn
														}
													}
													investigationSummary {
														id
													}
												}
											`,
										});

										return {
											...edgeRef,
											node: updatedAlertRef,
										};
									}

									return edgeRef;
								}),
							};
						},
					},
				});
			},
		});

		return response;
	}
}
