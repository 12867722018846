import { useCallback, useMemo, useRef, useState } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Form, FormProps } from '@progress/kendo-react-form';

import { Namespaces } from '@/translations/namespaces';

import { InvestigationType } from '@/generated/graphql';
import { Divider } from '@/app/_common/_components';
import { CreateInvestigationFormData, AssignToInvestigationFormData, AssignToInvestigationFormTabs } from '@/app/_common/_components/forms';
import { AssignToInvestigationTabs } from '@/app/_common/constants';
import { handleTabFormErrors } from '@/app/_common/utils/handle-tab-form-errors';
import { AlertsActionsViewStore, AlertsDataGridViewStore } from '@/app/_features/alerts-actions/_common';
import { AlertsActionDialog } from '@/app/_features/alerts-actions/_components';
import CreateInvestigationFormModule from '@/app/_common/_components/forms/create-investigation-form/create-investigation-form-module';
import AssignToInvestigationFormModule from '@/app/_common/_components/forms/assign-to-investigation-form/assign-to-investigation-form-module';
import { AlertsDataGrid } from '@/app/_features/alerts-actions/_components/alerts-data-grid/alerts-data-grid';
import { AuthStore } from '@/app/_common/stores';

const AlertsAssignDialog = observer(() => {
	const alertsActionsViewStore = useInstance(AlertsActionsViewStore);
	const alertsDataGridViewStore = useInstance(AlertsDataGridViewStore);
	const authStore = useInstance(AuthStore);

	const { t } = useTranslation([Namespaces.AlertsActionDialog], { keyPrefix: 'action.assign' });

	const [activeTab, setActiveTab] = useState<AssignToInvestigationTabs>(AssignToInvestigationTabs.createInvestigation);
	const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState<Record<AssignToInvestigationTabs, boolean>>({
		[AssignToInvestigationTabs.assignToInvestigation]: true,
		[AssignToInvestigationTabs.createInvestigation]: true,
	});

	const activeTabIdRef = useRef<AssignToInvestigationTabs>(AssignToInvestigationTabs.createInvestigation);
	const assignTemporaryFormValue = useRef<Partial<AssignToInvestigationFormData> | null>(null);
	const createTemporaryFormValue = useRef<Partial<CreateInvestigationFormData> | null>(null);
	const createInvestigationFormRef = useRef<Form>(null);
	const assignToInvestigationFormRef = useRef<Form>(null);

	const handleDialogClose = useCallback(() => {
		alertsActionsViewStore.closeDialog();
	}, [alertsActionsViewStore]);

	const handleSubmitButtonClick = useCallback(() => {
		if (activeTab === AssignToInvestigationTabs.createInvestigation) {
			handleCreateInvestigationFormSubmit(createInvestigationFormRef.current?.values as CreateInvestigationFormData);
		} else if (activeTab === AssignToInvestigationTabs.assignToInvestigation) {
			handleAssignToInvestigationFormSubmit(assignToInvestigationFormRef.current?.values as AssignToInvestigationFormData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	const handleCreateInvestigationFormSubmit = useCallback(
		(data: CreateInvestigationFormData) => {
			if (createInvestigationFormRef?.current?.isValid()) {
				const { assigneeId, franchiseAssigneeId, type, name, priority } = data;

				alertsActionsViewStore.executeCreateInvestigationAndAssignAlertsAlerts({
					assigneeId: assigneeId.value,
					type: type.value as InvestigationType,
					name,
					priority,
					...(authStore.isCurrentUserFranchiseUser && { franchiseAssigneeId: franchiseAssigneeId?.value }),
				});
			}
		},
		[alertsActionsViewStore, authStore.isCurrentUserFranchiseUser],
	);

	const handleAssignToInvestigationFormSubmit = useCallback(
		(data: AssignToInvestigationFormData) => {
			if (assignToInvestigationFormRef?.current?.isValid()) {
				alertsActionsViewStore.executeAssignAlerts({
					id: data.investigation.value.id,
					alertIds: data.investigation.value.alertIds,
				});
			}
		},
		[alertsActionsViewStore],
	);

	const handleFormTabChange = useCallback((tab: AssignToInvestigationTabs) => {
		if (tab !== activeTabIdRef.current) {
			if (tab === AssignToInvestigationTabs.createInvestigation) {
				assignTemporaryFormValue.current = assignToInvestigationFormRef?.current?.values as Partial<AssignToInvestigationFormData>;
				assignToInvestigationFormRef?.current?.onReset();

				const createTempValue = createTemporaryFormValue.current;

				if (createTempValue) {
					Object.keys(createTempValue).forEach((key) => {
						createInvestigationFormRef?.current?.valueSetter(key, createTempValue[key as keyof CreateInvestigationFormData]);
					});
				}
			} else if (tab === AssignToInvestigationTabs.assignToInvestigation) {
				createTemporaryFormValue.current = createInvestigationFormRef?.current?.values as Partial<CreateInvestigationFormData>;
				createInvestigationFormRef?.current?.onReset();

				const assignTempValue = assignTemporaryFormValue.current;

				if (assignTempValue) {
					Object.keys(assignTempValue).forEach((key) => {
						assignToInvestigationFormRef?.current?.valueSetter(key, assignTempValue[key as keyof Partial<AssignToInvestigationFormData>]);
					});
				}
			}

			setActiveTab(tab);
			activeTabIdRef.current = tab;
		}
	}, []);

	const handleFormErrors = useCallback(
		(errors: { [key: string]: string }, tab: AssignToInvestigationTabs) => {
			handleTabFormErrors(errors, tab, activeTab, setSubmitButtonsDisabled);
		},
		[activeTab, setSubmitButtonsDisabled],
	);

	const tabs = useMemo(
		() => [
			{
				id: AssignToInvestigationTabs.createInvestigation,
				title: t('tabs.create.title'),
				content: (
					<CreateInvestigationFormModule
						formRef={createInvestigationFormRef}
						onSubmit={handleCreateInvestigationFormSubmit as FormProps['onSubmit']}
						disabled={activeTab !== AssignToInvestigationTabs.createInvestigation}
						onErrorsChange={handleFormErrors}
					/>
				),
			},
			{
				id: AssignToInvestigationTabs.assignToInvestigation,
				title: t('tabs.assign.title'),
				content: (
					<AssignToInvestigationFormModule
						formRef={assignToInvestigationFormRef}
						onSubmit={handleAssignToInvestigationFormSubmit as FormProps['onSubmit']}
						disabled={activeTab !== AssignToInvestigationTabs.assignToInvestigation}
						onErrorsChange={handleFormErrors}
					/>
				),
			},
		],
		[t, activeTab, handleCreateInvestigationFormSubmit, handleAssignToInvestigationFormSubmit, handleFormErrors],
	);

	return (
		<AlertsActionDialog
			title={t('title')}
			onClose={handleDialogClose}
			onSubmit={handleSubmitButtonClick}
			errors={alertsActionsViewStore.errors}
			isOpen={true}
			disabled={
				alertsDataGridViewStore.selectedElements.length === 0 ||
				submitButtonsDisabled[AssignToInvestigationTabs.assignToInvestigation] ||
				submitButtonsDisabled[AssignToInvestigationTabs.createInvestigation]
			}
			loading={alertsActionsViewStore.loading}
			cancelButtonText={t('buttons.cancel')}
			submitButtonText={activeTab !== AssignToInvestigationTabs.createInvestigation ? t('buttons.assign') : t('buttons.create')}
		>
			<AlertsDataGrid store={alertsDataGridViewStore} />
			<Divider />
			<AssignToInvestigationFormTabs active={activeTab} tabs={tabs} onTabButtonClick={handleFormTabChange} onTabContentFocus={handleFormTabChange} />
		</AlertsActionDialog>
	);
});

AlertsAssignDialog.displayName = 'AlertsAssignDialog';
export { AlertsAssignDialog };
