import { Controller, FieldValues } from 'react-hook-form';
import { FieldPath, UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Field } from '@progress/kendo-react-form';

import {
	BaseFieldProps,
	ControlledFieldComponentProps,
	FieldComponentProps,
	NameField,
} from '@/app/_common/_components/integration-details-form/types';
import { FormInput } from '@/app/_common/_components';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@/app/_common/_components/integration-details-form/constants';
import { NameValidationRegEx } from '@/app/_common/constants';

const BaseField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	disabled,
	register,
	t,
	controllerProps = {},
}: BaseFieldProps<TFieldValues, TName>) => (
	<Field
		{...controllerProps}
		id={NameField.Name}
		label={t(NameField.Label)}
		type={NameField.Type}
		component={FormInput}
		touched={true}
		placeholder={disabled ? undefined : t(NameField.Placeholder)}
		optional={false}
		valid={false}
		disabled={disabled}
		{...register(NameField.Name as TName, {
			required: true,
			maxLength: NAME_MAX_LENGTH,
			minLength: NAME_MIN_LENGTH,
			pattern: NameValidationRegEx,
		})}
	/>
);

const ControlledNameFieldComponent = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
	props: ControlledFieldComponentProps<TFieldValues>,
) => {
	const { control } = props;

	return (
		<Controller<TFieldValues>
			render={(controllerProps: {
				field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
				fieldState: ControllerFieldState;
				formState: UseFormStateReturn<TFieldValues>;
			}) => <BaseField controllerProps={controllerProps} {...props} />}
			name={NameField.Name as TName}
			control={control}
		/>
	);
};

ControlledNameFieldComponent.displayName = 'ControlledNameFieldComponent';

export const NameFieldComponent = <TFieldValues extends FieldValues>({ disabled, register, t, control }: FieldComponentProps<TFieldValues>) => {
	if (!control) {
		return <BaseField disabled={disabled} register={register} t={t} />;
	}

	return <ControlledNameFieldComponent disabled={disabled} register={register} t={t} control={control} />;
};
