import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { IntegrationFormData, ValidateIntegrationResult } from '@/app/create-integration/_common/interfaces';
import { ValidateIntegrationDataStore } from './validate-integration.data-store';
import { ValidateIntegrationParametersInput, IntegrationType } from '@/generated/graphql';
import { getIntegrationComponents } from '@/app/create-integration/_common/utils';

interface ValidationResultError {
	message?: string | null;
}

interface State {
	validationResult: ValidateIntegrationResult | null;
	validationTrackingId: string;
	validationResultError: ValidationResultError;
}

const INITIAL_STATE = {
	validationResult: null,
	validationTrackingId: '',
	validationResultError: {
		message: null,
	},
};

export class ValidateIntegrationViewStore {
	private state: State = INITIAL_STATE;
	private dataStore = injectInterface(this, ValidateIntegrationDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		this.createNewValidationTrackingId();
	}

	validateIntegration = async (formContent: IntegrationFormData, integrationType: IntegrationType, definitionId: string) => {
		if (!definitionId) {
			return;
		}

		this.createNewValidationTrackingId();

		this.setValidationResult(ValidateIntegrationResult.PENDING);

		const integrationComponents = getIntegrationComponents(formContent);

		const args: ValidateIntegrationParametersInput = {
			components: integrationComponents,
			trackingId: this.state.validationTrackingId,
			definitionId,
			integrationType,
		};

		this.dataStore.validate(args);
	};

	get validationTrackingId() {
		return this.state.validationTrackingId;
	}

	get validationResult() {
		return this.state.validationResult;
	}

	get validationResultError() {
		return this.state.validationResultError;
	}

	get isValidationSuccess() {
		return this.state.validationResult === ValidateIntegrationResult.SUCCESS;
	}

	setValidationResult = (result: ValidateIntegrationResult, error?: ValidationResultError) => {
		this.state.validationResult = result;

		if (result === ValidateIntegrationResult.FAILED && error) {
			this.state.validationResultError = error;
		}
	};

	resetValidationResult = () => {
		this.state.validationResult = null;
	};

	createNewValidationTrackingId = () => {
		this.state.validationTrackingId = uuid();
	};
}
