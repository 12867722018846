import { makeAutoObservable, reaction } from 'mobx';
import _flatten from 'lodash/flatten';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { IntegrationDefinition, IntegrationType } from '@/generated/graphql';
import { IntegrationDefinitionsDataStore } from './integration-definitions.data-store';
import { IntegrationsView } from '@/app/create-integration/_common/interfaces';
import { RootPaths } from '@/app/_common/navigation';

interface State {
	search: string;
	definition?: IntegrationDefinition;
	integrationsView: IntegrationsView;
	integrationCategoryFilters: string[];
	integrationType: IntegrationType;
}
const initialState: State = {
	search: '',
	definition: undefined,
	integrationsView: IntegrationsView.Grid,
	integrationCategoryFilters: [],
	integrationType: IntegrationType.Telemetry,
};

export class ChooseIntegrationStepViewStore {
	private state: State = initialState;

	private dataStore = injectInterface(this, IntegrationDefinitionsDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		if (window.location.pathname !== RootPaths.CREATE_TELEMETRY_INTEGRATION) {
			this.setIntegrationType(IntegrationType.Response);
		} else {
			this.setIntegrationType(IntegrationType.Telemetry);
		}

		this.read(this.integrationType);
	}

	get searchedIntegrationDefinitions(): IntegrationDefinition[] {
		if (this.dataStore.integrationDefinitions) {
			return this.dataStore.integrationDefinitions.filter(
				(container) =>
					this.isSearchMatchingIntegration(container.vendor.name, container.product) && this.isIntegrationCategorySelected(container.categories),
			);
		}
		return [];
	}

	get loading() {
		return this.dataStore.loading;
	}

	get definition() {
		return this.state.definition;
	}

	get components() {
		return this.isCreatingResponseIntegration ? this.state.definition?.responseComponents : this.state.definition?.components;
	}

	get definitionId() {
		return this.state.definition?.id ?? '';
	}

	get definitionProductName() {
		return this.state.definition?.product;
	}

	get definitionVendorName() {
		return this.state.definition?.vendor.name;
	}

	get searchValue() {
		return this.state.search;
	}

	get integrationsView() {
		return this.state.integrationsView;
	}

	get integrationCategoryFilters() {
		return this.state.integrationCategoryFilters;
	}

	get integrationCategories(): string[] {
		if (this.dataStore.integrationDefinitions) {
			const categories = this.dataStore.integrationDefinitions.map((container) => {
				if (container.categories) {
					return container.categories;
				}
				return [];
			});

			return Array.from(new Set([..._flatten(categories)]));
		}
		return [];
	}

	get definitionDocumentationUrl(): string {
		return this.definition?.documentationUrl || '';
	}

	get isCreatingResponseIntegration(): boolean {
		return this.state.integrationType === IntegrationType.Response;
	}

	get integrationType(): IntegrationType {
		return this.state.integrationType;
	}

	onIntegrationCategoryClick = (category: string): void => {
		const index = this.state.integrationCategoryFilters.indexOf(category);
		if (index > -1) {
			this.state.integrationCategoryFilters.splice(index, 1);
		} else {
			this.state.integrationCategoryFilters.push(category);
		}
	};

	setIntegrationsView = (value: IntegrationsView) => {
		this.state.integrationsView = value;
	};

	setIntegrationType = (type: IntegrationType) => {
		this.state.integrationType = type;
	};

	setSearchValue = (value: string): void => {
		this.state.search = value.toLocaleLowerCase();
	};

	setSelectedIntegrationDefinition = (integrationDefinition: IntegrationDefinition): void => {
		this.state.definition = integrationDefinition;
	};

	resetSelectedIntegrationDefinition = () => {
		this.state.definition = undefined;
	};

	read = (integrationType: IntegrationType): void => {
		this.dataStore.read(integrationType);
	};

	private isIntegrationCategorySelected = (categories?: string[] | null) => {
		if (this.integrationCategoryFilters.length !== 0) {
			return this.integrationCategoryFilters.some((category) => categories?.includes(category));
		}
		return true;
	};

	private isSearchMatchingIntegration = (vendor: string, product: string) => {
		return vendor.toLocaleLowerCase().includes(this.state.search) || product.toLocaleLowerCase().includes(this.state.search);
	};

	private fetchListIntegrationsDisposer = reaction(
		() => this.state.integrationType,
		(type) => {
			if (!type) {
				return;
			}

			this.read(type);
		},
	);

	dispose() {
		this.fetchListIntegrationsDisposer();
	}
}
