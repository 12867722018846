import { action, computed, makeObservable, reaction } from 'mobx';

import { SortDescriptor } from '@progress/kendo-data-query';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { IntegrationEdge, IntegrationStatus } from '@/generated/graphql';
import { FilterOption } from '@/app/_common/types';
import { getNullishFilterOption, sortIntegration } from '@/app/_common/utils';
import { IntegrationsPropertiesPaths, SortDirection, SyslogCollectorIntegrationPropertiesPaths } from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { SyslogCollectorIntegrationsDataStore } from '@/app/collectors/_common/stores';
import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';
import { getIntegrationStatusLabelSuffix } from '@/app/telemetry/integrations/_common';

const EMPTY_FIELD_VALUE = 'empty';

const INTEGRATIONS_LIST_VERSION = 'v1';
const INITIAL_COLUMNS = {
	[SyslogCollectorIntegrationPropertiesPaths.Status]: true,
	[SyslogCollectorIntegrationPropertiesPaths.Vendor]: true,
	[SyslogCollectorIntegrationPropertiesPaths.ProductName]: true,
	[SyslogCollectorIntegrationPropertiesPaths.Hostname]: true,
	[SyslogCollectorIntegrationPropertiesPaths.LastEventTimestamp]: true,
	[SyslogCollectorIntegrationPropertiesPaths.Timestamp]: true,
};

const INITIAL_SORT: SortDescriptor[] = [{ field: SyslogCollectorIntegrationPropertiesPaths.Vendor, dir: SortDirection.Asc }];

export class SyslogCollectorIntegrationsViewStore extends DataGridViewStore<IntegrationEdge> {
	private dataStore = injectInterface(this, SyslogCollectorIntegrationsDataStore);

	constructor() {
		super(IntegrationsPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortIntegration, false, INTEGRATIONS_LIST_VERSION, true);

		makeObservable(this, {
			loading: computed,
			error: computed,
			hasNextPage: computed,
			read: action,
			readMore: action,
		});

		this.read();
	}

	get error() {
		return this.dataStore.error;
	}
	get loading() {
		return this.dataStore.loading;
	}

	getFilterOptions = (field: string, isNull?: boolean, emptyFieldLabel?: string) => {
		const counters = this.getCountedValues(field);

		const options: FilterOption[] = [
			...this.getUniqValues(field).map((value) => {
				const valueString = String(value);

				const partialOption = {
					value: valueString,
					counter: counters[valueString],
				};

				let label = valueString;

				if (field === IntegrationsPropertiesPaths.Status) {
					label = i18n.t(`grid.status.${getIntegrationStatusLabelSuffix(value as IntegrationStatus)}`, { ns: Namespaces.Integrations });
				}

				return {
					...partialOption,
					label,
				};
			}),
		];

		const isNullishFilterOption = isNull && emptyFieldLabel && counters[EMPTY_FIELD_VALUE];

		if (isNullishFilterOption) {
			options.unshift(getNullishFilterOption(emptyFieldLabel, EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}
		return options;
	};

	get hasNextPage() {
		return this.dataStore.pageInfo?.hasNextPage;
	}

	readMore = () => {
		this.dataStore.readMore();
	};

	read = () => {
		this.dataStore.read();
	};

	integrationsDisposer = reaction(
		() => this.dataStore.integrations,
		(integrations) => {
			this.sourceData = integrations;
		},
	);

	dispose = () => {
		this.integrationsDisposer();
	};
}
