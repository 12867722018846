import _omit from 'lodash/omit';

import { IntegrationFormData } from '@/app/create-integration/_common/interfaces';
import { CreateIntegrationComponent } from 'tests/mocks/server/types/graphql';

export const getIntegrationComponents = (formContent: IntegrationFormData): CreateIntegrationComponent[] => {
	const componentsObj = _omit(formContent, ['name', 'description']);
	const keys = Object.keys(componentsObj);

	const components = keys.map((key) => ({ key, value: componentsObj[key] }));

	return [{ configuration: components }];
};
