import { useCallback, useMemo } from 'react';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

import { Query, ValueOperator } from '@/generated/graphql';
import { DataGridDataItem, DataGridSelected, ResultsDataItem } from '@/app/_common/types';
import { AdxTables, RESULTS_ALERT_ID_PATH, RESULTS_ARTIFICIAL_ID_PATH } from '@/app/_common/constants';
import { ListResultsAlertsByIds, ListInvestigationsByIds } from '@/app/_common/graphql/queries';
import { ContextMenuConfig } from '@/app/_common/_components/new-context-menu';
import { isTruthy } from '@/app/_common/utils/is-truthy';

export const useQueryResultsTableContextMenuConfig = <T>(
	selectedItems: DataGridDataItem<ResultsDataItem>[],
	onSelectRow: (selectedRows: DataGridSelected) => void,
	disableLookup: boolean,
	selectedElementsIds: string[],
): ContextMenuConfig<T> => {
	const selectedArtificialIds = useMemo(() => {
		return _uniq(selectedElementsIds);
	}, [selectedElementsIds]);

	const selectedAlertsIds = useMemo(() => {
		return _uniq(
			selectedItems.reduce<string[]>((result, resultItem) => {
				const artificialId = _get(resultItem, RESULTS_ARTIFICIAL_ID_PATH) ?? '';
				const isSelected = selectedArtificialIds.includes(artificialId);

				if (isSelected) {
					const isAlert = resultItem.$table === AdxTables.Alerts;
					const id = _get(resultItem, RESULTS_ALERT_ID_PATH);

					if (isAlert && typeof id === 'string') {
						result.push(id);
					}
				}

				return result;
			}, []),
		);
	}, [selectedArtificialIds, selectedItems]);

	const handleOpenContextMenu = useCallback(
		(_: string, dataItem: T) => {
			const node = dataItem;
			const artificialId = _get(node, RESULTS_ARTIFICIAL_ID_PATH);

			if (artificialId && !selectedArtificialIds.includes(artificialId)) {
				onSelectRow({ [artificialId]: true });
			}
		},
		[selectedArtificialIds, onSelectRow],
	);

	const queryDefinitions = useMemo(() => {
		const definitions = [];

		if (disableLookup) {
			return [];
		}

		if (selectedAlertsIds.length) {
			definitions.push({
				query: ListResultsAlertsByIds,
				filtersInput: {
					valueFilters: [
						{
							field: 'id',
							operator: ValueOperator.IsIn,
							values: selectedAlertsIds,
						},
					],
				},
			});

			definitions.push((data?: Pick<Query, 'listAlerts'>) => {
				const investigationIds = data?.listAlerts?.edges.map(({ node }) => node?.investigationSummary?.id).filter(isTruthy) ?? [];

				if (investigationIds.length > 0) {
					return {
						query: ListInvestigationsByIds,
						filtersInput: {
							valueFilters: [
								{
									field: 'id',
									operator: ValueOperator.IsIn,
									values: investigationIds,
								},
							],
						},
					};
				}
			});
		}

		return definitions;
	}, [selectedAlertsIds, disableLookup]);

	const config = useMemo(
		() => ({
			queryDefinitions,
			onOpen: handleOpenContextMenu,
		}),
		[handleOpenContextMenu, queryDefinitions],
	);

	return config;
};
