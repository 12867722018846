import { KeyboardEvent, MouseEvent, ReactNode, useMemo } from 'react';
import _uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';

import BusinessIcon from '@mui/icons-material/Business';

import { Tooltip } from '@/app/_common/_components';
import { buttonize } from '@/app/_common/utils';

import styles from './avatar.module.scss';

export interface AvatarProps {
	name: string;
	active?: boolean;
	overlap?: boolean;
	tooltipEnabled?: boolean;
	tooltipContent?: ReactNode;
	onClick?: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
	isFranchiseUser?: boolean;
}

export const Avatar = ({
	name,
	active = false,
	overlap = false,
	tooltipEnabled = true,
	tooltipContent = name,
	onClick,
	isFranchiseUser = false,
}: AvatarProps) => {
	const tooltipId = useMemo(() => `avatar.${_uniqueId()}`, []);

	const tooltipAttributes = tooltipEnabled
		? {
				'data-tip': tooltipId,
				'data-for': tooltipId,
		  }
		: {};
	const clickEventListeners = useMemo(() => (onClick ? buttonize(onClick) : {}), [onClick]);

	return (
		<div className={styles.container}>
			<div
				data-testid="avatar"
				{...tooltipAttributes}
				{...clickEventListeners}
				className={classNames(styles.avatar, { [styles.active]: active, [styles.overlap]: overlap, [styles.clickable]: !!onClick })}
			>
				{/* name.slice(0, 2): temporary solution until we have only emails - upn's*/}
				{isFranchiseUser ? <BusinessIcon htmlColor="var(--franchiseIcon)" /> : name.slice(0, 2)}
			</div>
			{tooltipEnabled && <Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} />}
		</div>
	);
};
