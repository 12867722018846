import i18n from '@/translations/i18n';

import {
	AllInbox,
	Dashboard,
	MyLocation,
	NotificationImportant,
	Policy,
	Settings,
	Pageview,
	MoveToInbox,
	Speed,
	AccountTree,
	Laptop,
	SupervisedUserCircle,
	History,
	Handyman,
} from '@mui/icons-material';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { Namespaces } from '@/translations/namespaces';
import { CogConnectIcon, HeartPulseIcon } from '@/app/_common/_components';
import { MenuItem } from './interfaces';
import { FeaturesFlag } from '@/app/_common/interfaces';

export const menuItems: MenuItem[] = [
	{
		text: i18n.t('dashboards.main', { ns: Namespaces.Sidebar }),
		Icon: Dashboard,
		route: RootPaths.DASHBOARDS,
		subitems: [
			{
				text: i18n.t('dashboards.alertsManagement', { ns: Namespaces.Sidebar }),
				Icon: NotificationImportant,
				route: RootPaths.ALERTS_DASHBOARDS,
				display: true,
			},
			{
				text: i18n.t('dashboards.telemetryMonitoring', { ns: Namespaces.Sidebar }),
				Icon: HeartPulseIcon,
				route: RootPaths.TELEMETRY_DASHBOARD,
				display: true,
			},
		],
	},
	{
		text: i18n.t('investigations', { ns: Namespaces.Sidebar }),
		Icon: Policy,
		route: RootPaths.INVESTIGATIONS,
		hasNestedDynamicSubroutes: true,
	},
	{
		text: i18n.t('advancedQuery.main', { ns: Namespaces.Sidebar }),
		Icon: MyLocation,
		route: RootPaths.ADVANCED_QUERY,
		display: true,
		subitems: [
			{
				text: i18n.t('advancedQuery.hunts', { ns: Namespaces.Sidebar }),
				Icon: Pageview,
				route: RootPaths.ADVANCED_QUERY,
				display: false,
			},
		],
	},
	{
		text: i18n.t('telemetry.main', { ns: Namespaces.Sidebar }),
		Icon: Speed,
		route: RootPaths.TELEMETRY,
		hasNestedDynamicSubroutes: true,
		display: true,
		subitems: [
			{
				text: i18n.t('telemetry.collectors', { ns: Namespaces.Sidebar }),
				Icon: AllInbox,
				route: RootPaths.COLLECTORS,
				hasNestedDynamicSubroutes: true,
				display: true,
			},
			{
				text: i18n.t('telemetry.integrations', { ns: Namespaces.Sidebar }),
				Icon: MoveToInbox,
				route: RootPaths.TELEMETRY_INTEGRATIONS,
				hasNestedDynamicSubroutes: true,
				display: true,
			},
		],
	},
	{
		text: i18n.t('response.main', { ns: Namespaces.Sidebar }),
		Icon: AccountTree,
		route: RootPaths.RESPONSE,
		hasNestedDynamicSubroutes: true,
		display: true,
		subitems: [
			{
				text: i18n.t('response.integrations', { ns: Namespaces.Sidebar }),
				Icon: CogConnectIcon,
				route: RootPaths.RESPONSE_INTEGRATIONS,
				display: true,
			},
			{
				text: i18n.t('response.endpoints', { ns: Namespaces.Sidebar }),
				Icon: Laptop,
				route: RootPaths.RESPONSE_ENDPOINTS,
				display: true,
			},
		],
	},
	{
		text: i18n.t('tools.main', { ns: Namespaces.Sidebar }),
		Icon: Handyman,
		route: RootPaths.TOOLS,
		feature: FeaturesFlag.COMPROMISE_MONITOR_ENABLED,
		hasNestedDynamicSubroutes: true,
	},
	{
		text: i18n.t('management.main', { ns: Namespaces.Sidebar }),
		Icon: Settings,
		route: RootPaths.MANAGEMENT,
		subitems: [
			{
				text: i18n.t('management.users', { ns: Namespaces.Sidebar }),
				Icon: SupervisedUserCircle,
				route: RootPaths.MANAGEMENT_USERS,
				display: true,
			},
			{
				text: i18n.t('management.actionHistory', { ns: Namespaces.Sidebar }),
				Icon: History,
				route: RootPaths.MANAGEMENT_ACTION_HISTORY,
				display: true,
			},
		],
	},
];
